import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Spinner from '../../components/Spinner';
import {BASEURL, token} from '../../shared/Service';
import {API} from '../../shared/utility';

const UserProfile = () => {
	const {id} = useParams();
	const [selectedSection, setSelectedSection] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [personalDetailsData, setPersonalDetailsData] = useState();
	const [educationalDetailsData, setEducationalDetailsData] = useState();
	const [professionalDetailsData, setProfessionalDetailsData] = useState();
	const [sewaDetailsData, setSewaDetailsData] = useState();

	useEffect(() => {
		GetUserDetail();
		GetEducationDetails();
		GetProfessionalDetails();
		GetSewaDetails();
	}, []);

	const GetUserDetail = async () => {
		setIsLoading(true);
		API.GET_USER_DETAIL(id)
			.then((res) => {
				const data = res.data.data;

				setPersonalDetailsData(data);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err.response.data.message);
				setIsLoading(false);
			});
	};

	const GetEducationDetails = async () => {
		const userId = id;

		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		};

		if (userId) {
			setIsLoading(true);
			axios
				.get(`${BASEURL}/education/${userId}`, {headers: headers})
				.then((res) => {
					setEducationalDetailsData(res.data.data);
					setIsLoading(false);
				})
				.catch((err) => {
					setIsLoading(false);
					console.log(err.response.data.message);
				});
		}
	};

	const GetProfessionalDetails = async () => {
		const userId = id;

		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		};

		if (userId) {
			setIsLoading(true);
			axios
				.get(`${BASEURL}/profession/${userId}`, {headers: headers})
				.then((res) => {
					setProfessionalDetailsData(res.data.data);

					setIsLoading(false);
				})
				.catch((err) => {
					setIsLoading(false);
					console.log(err.response.data.message);
				});
		}
	};

	const GetSewaDetails = async () => {
		// const userToken = localStorage.getItem('userToken');
		const userId = id;

		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		};

		if (userId) {
			setIsLoading(true);
			axios
				.get(`${BASEURL}/welfare/${userId}`, {headers: headers})
				.then((res) => {
					setSewaDetailsData(res.data.data);
					setIsLoading(false);
				})
				.catch((err) => {
					setIsLoading(false);

					console.log(err.response.data.message);
				});
		}
	};

	const getSelectedTab = () => {
		switch (selectedSection) {
			case 0:
				return <Personal personalDetailsData={personalDetailsData} />;
			case 1:
				return <Education educationalDetailsData={educationalDetailsData} />;
			case 2:
				return <Professional professionalDetailsData={professionalDetailsData} />;
			case 3:
				return <Welfare sewaDetailsData={sewaDetailsData} />;

			default:
				return <Personal personalDetailsData={personalDetailsData} />;
		}
	};

	const sectionSelectorStyle = 'px-2 sm:px-3 md:px-6 pb-black text-sm whitespace-nowrap sm:text-lg md:text-xl border-blue-500 cursor-pointer';
	return (
		<>
			{isLoading ? (
				<div className="w-full h-[100vh] flex items-center justify-center  bg-white  rounded-lg">
					<Spinner />
				</div>
			) : (
				<div className=" h-full min-h-[100vh] w-full bg-blue-100 py-10 rounded-lg">
					<div className="w-[90%] md:max-w-6xl m-auto">
						<div className="flex items-center justify-between mb-3">
							<h1 className="text-2xl w-fit">User Details</h1>
						</div>
						<div>
							<div className="flex overflow-x-scroll hide-scrollbar relative h-[50px] mt-6 mb-10">
								<div
									className={selectedSection === 0 ? `${sectionSelectorStyle} pl-0 sm:pl-2 border-b-4 border-blue-500` : `${sectionSelectorStyle} pl-0 sm:pl-2 border-b-0`}
									onClick={() => setSelectedSection(0)}>
									Personal
								</div>
								<div
									className={selectedSection === 1 ? `${sectionSelectorStyle} pl-0 sm:pl-2 border-b-4 border-blue-500` : `${sectionSelectorStyle} pl-0 sm:pl-2 border-b-0`}
									onClick={() => setSelectedSection(1)}>
									Education
								</div>
								<div
									className={selectedSection === 2 ? `${sectionSelectorStyle} border-b-4 border-blue-500` : `${sectionSelectorStyle} border-b-0`}
									onClick={() => setSelectedSection(2)}>
									Professional
								</div>
								<div
									className={selectedSection === 3 ? `${sectionSelectorStyle} border-b-4 border-blue-500` : `${sectionSelectorStyle} border-b-0`}
									onClick={() => setSelectedSection(3)}>
									Welfare
								</div>
								<div className="border-b border-blue-500 mb-4 absolute bottom-[-15.4px] w-full"></div>
							</div>

							{getSelectedTab()}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const Personal = ({personalDetailsData}) => {
	return (
		<div className={`flex bg-white  mx-auto shadow-lg rounded-lg `}>
			{personalDetailsData ? (
				<div className="flex flex-col justify-between w-full p-4 sm:flex-row md:px-10 md:py-4 ">
					{/* Ex student details */}
					<div className="">
						<div className="mb-2 text-xl font-medium text-gray-700">Personal Details</div>
						{/* name */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Name:</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.name}</span>
							</div>
						</div>
						{/* f' name */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Father's Name:</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.fatherName}</span>
							</div>
						</div>
						{/* gender */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Gender</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.gender}</span>
							</div>
						</div>
						{/* dob */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Date of birth:</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.dob.substring(0, 10)}</span>
							</div>
						</div>
						{/* phno. calling */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Phone number (calling):</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.callingPhoneNo}</span>
							</div>
						</div>
						{/* phno. whatsapp */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Phone number (whatsapp):</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.whatsappPhoneNo}</span>
							</div>
						</div>
						{/* married */}
						{/* <div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Married:</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.maritalStatus ? 'married' : 'unmarried'}</span>
							</div>
						</div> */}
						{/* bloodGroup */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Blood Group :</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.bloodGroup}</span>
							</div>
						</div>
						{/* country */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Country:</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.country}</span>
							</div>
						</div>
						{/* state */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">State:</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.state}</span>
							</div>
						</div>
						{/* district */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">District:</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.district}</span>
							</div>
						</div>

						{/* village */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Village :</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.village}</span>
							</div>
						</div>
						{/* block */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Block:</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.block}</span>
							</div>
						</div>

						{/* address */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Address:</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.address}</span>
							</div>
						</div>

						{/* caste */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Caste :</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.caste}</span>
							</div>
						</div>

						{/* vehicle */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Vehicle :</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.vehicle}</span>
							</div>
						</div>

						{/* politicalParty */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">any Connection with Political Party :</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.politicalParty}</span>
							</div>
						</div>

						{/* socialDesignation */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Any Social Designation:</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.socialDesignation}</span>
							</div>
						</div>
						{/* knownOfficers */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Any Relation with Goverment Officer :</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{personalDetailsData.knownOfficers}</span>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="flex items-center justify-center w-full h-[100px]">Data not found</div>
			)}
		</div>
	);
};

const Education = ({educationalDetailsData}) => {
	return (
		<div className={`flex bg-white  mx-auto shadow-lg rounded-lg `}>
			{educationalDetailsData ? (
				<div className="flex flex-col w-full">
					<div className="p-4 border-b-2 border-gray-200 md:px-10 md:py-4">
						<div className="mb-2 text-xl font-medium text-gray-700">Education Details</div>
						{/* highest education */}
						<div className="flex items-center justify-between mb-2">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Highest Education:</span>
								<span className="ml-1 text-gray-700 capitalize text-md">{educationalDetailsData.highestEducation}</span>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="flex items-center justify-center w-full h-[100px]">Data not found</div>
			)}
		</div>
	);
};

const Professional = ({professionalDetailsData}) => {
	return (
		<div className={`flex bg-white  mx-auto shadow-lg rounded-lg `}>
			{professionalDetailsData ? (
				<div className="flex flex-col w-full">
					<div className="p-4 md:px-10 md:py-4">
						<div className="mb-3 text-xl font-medium text-gray-700">Occupation Details</div>
						{/* Current Profession */}
						<div className="flex items-center justify-between mb-3">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Current Profession:</span>
								<span className="ml-3 text-gray-700 text-md">{professionalDetailsData.currentProfession}</span>
							</div>
						</div>

						{(professionalDetailsData.currentProfession == 'private' ||
							professionalDetailsData.currentProfession == 'government' ||
							professionalDetailsData.currentProfession == 'businessman') && (
							<div className="flex items-center justify-between mb-3">
								<div className="flex items-center">
									<span className="font-medium text-gray-400 capitalize text-md">Sector:</span>
									<span className="ml-3 text-gray-700 text-md">{professionalDetailsData.sector}</span>
								</div>
							</div>
						)}

						{(professionalDetailsData.currentProfession == 'private' || professionalDetailsData.currentProfession == 'government') && (
							<div className="flex items-center justify-between mb-3">
								<div className="flex items-center">
									<span className="font-medium text-gray-400 capitalize text-md">Designation:</span>
									<span className="ml-3 text-gray-700 text-md">{professionalDetailsData.designation}</span>
								</div>
							</div>
						)}

						{professionalDetailsData.currentProfession == 'businessman' && (
							<>
								<div className="flex items-center justify-between mb-3">
									<div className="flex items-center">
										<span className="font-medium text-gray-400 capitalize text-md"> Occupation Name:</span>
										<span className="ml-3 text-gray-700 text-md">{professionalDetailsData.address}</span>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			) : (
				<div className="flex items-center justify-center w-full h-[100px]">Data not found</div>
			)}
		</div>
	);
};

const Welfare = ({sewaDetailsData}) => {
	return (
		<div className={`flex bg-white  mx-auto shadow-lg rounded-lg `}>
			{sewaDetailsData ? (
				<div className="flex flex-col w-full">
					<div className="p-4 md:px-10 md:py-4">
						<div className="mb-3 text-xl font-medium text-gray-700">Sewa Details</div>
						{sewaDetailsData.isPakkiSamitiMember ? (
							<>
								{/* pakkiSamiti */}
								<div className="flex items-center justify-between mb-3">
									<div className="flex items-center">
										<span className="font-medium text-gray-400 capitalize text-md">Pakki sammitti:</span>
										<span className="ml-3 text-lg text-gray-700">{sewaDetailsData.pakkiSamiti}</span>
									</div>
								</div>
							</>
						) : (
							<div className="flex items-center justify-between mb-3">
								<div className="flex items-center">
									<span className="font-medium text-gray-400 capitalize text-md">Pakki Sammitti:</span>
									<span className="ml-3 text-lg text-gray-700">No</span>
								</div>
							</div>
						)}
						{/* naamDate */}
						<div className="flex items-center justify-between mb-3">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Naam Year:</span>
								<span className="ml-3 text-lg text-gray-700">{sewaDetailsData.naamDate}</span>
							</div>
						</div>
						{/* jaamDate */}
						<div className="flex items-center justify-between mb-3">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Jaam Year:</span>
								<span className="ml-3 text-lg text-gray-700">{sewaDetailsData.jaamDate}</span>
							</div>
						</div>

						{/* uniformNumber */}
						<div className="flex items-center justify-between mb-3">
							<div className="flex items-center">
								<span className="font-medium text-gray-400 capitalize text-md">Green-S-Welfare Uniform No.:</span>
								<span className="ml-3 text-lg text-gray-700">{sewaDetailsData.uniformNumber}</span>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="flex items-center justify-center w-full h-[100px]">Data not found</div>
			)}
		</div>
	);
};

export default UserProfile;
