import React from 'react';

const StatCard = ({loading, title, count}) => {
	return (
		<>
			<div className="flex flex-col justify-center items-center text-center bg-blue-100 h-[150px] w-full rounded-lg shadow-md shadow-gray-300">
				<p className={`text-3xl font-bold leading-none text-gray-500 ${loading && 'animate-ping'}`}>{count}</p>

				<p className={`mb-1 text-base font-medium text-gray-500 capitalize mt-4`}>{title}</p>
			</div>
		</>
	);
};

export default StatCard;
