import React, { useEffect, useState } from "react";
import { sammittiArray } from "../../shared/data";
import { errorToast } from "../../shared/Service";
import { API } from "../../shared/utility";
import ButtonsLayout from "../ButtonsLayout";
import Spinner from "../Spinner";

const SewaDetails = ({
  currentTab,
  changeTab,
  setShowSuccessModal,
  isUpdating,
}) => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  const [data, setData] = useState({
    isPakkiSamitiMember: false,
    pakkiSamiti: "",
    jaamDate: null,
    naamDate: "",
    uniformNumber: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(true);
  const [updateData, setUpdateData] = useState({});
  const [isPakkiSamitiOther, setIsPakkiSamitiOther] = useState(false);
  const [showGreenSwefareFiled, setShowGreenSwefareFiled] = useState(false);

  const isCategoryC = JSON.parse(localStorage.getItem("isCategoryC"));

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleUpdateChange = (event) => {
    setUpdateData({ ...updateData, [event.target.name]: event.target.value });
  };

  const GetSewaDetails = async () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setIsLoading(true);
      API.GET_WELFARE_DETAIL(userId)
        .then((res) => {
          res?.data?.data?.length === 0
            ? setIsNewRecord(true)
            : setIsNewRecord(false);
          const {
            isPakkiSamitiMember,
            pakkiSamiti,
            jaamDate,
            naamDate,
            uniformNumber,
          } = res.data.data;
          const sewaDetails = {
            isPakkiSamitiMember,
            pakkiSamiti,
            jaamDate,
            naamDate,
            uniformNumber,
          };
          uniformNumber
            ? setShowGreenSwefareFiled(true)
            : setShowGreenSwefareFiled(false);

          setData(sewaDetails);
        })
        .catch((err) => {
          // errorToast('Opps! Something went wrong');
          console.log(err);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    GetSewaDetails();
  }, []);

  return (
    <div className={`${currentTab === 3 ? "block" : "hidden"}`}>
      {isLoading ? (
        <div className="w-full h-[50vh] flex items-center justify-center bg-white  rounded-lg">
          <Spinner />
        </div>
      ) : (
        <>
          <div className={`w-full flex-col bg-white  flex rounded-lg`}>
            {/* Sewa Details*/}
            <div className="flex flex-col p-3 sm:p-5 shrink h-fit md:flex-1">
              <h1 className="my-3 text-xl text-gray-700 sm:my-4 sm:text-2xl">
                Sewa Details
              </h1>
              <form action="#" className="flex flex-col space-y-5">
                {/* Are you already a member of any pakki sammitti/sewa group? */}
                <div className="flex flex-col space-y-1">
                  <label
                    htmlFor="sewa-memeber"
                    className={`text-sm capitalize ${
                      !isCategoryC && "required"
                    }`}
                  >
                    Are you already a member of any pakki sammitti/sewa group?
                  </label>
                  <div className="flex  gap-2">
                    <span>
                      <input
                        type="radio"
                        name="isPakkiSamitiMember"
                        value="no"
                        checked={!data.isPakkiSamitiMember}
                        onChange={() => {
                          if (!isNewRecord) {
                            setUpdateData({
                              ...updateData,
                              isPakkiSamitiMember: false,
                              pakkiSamiti: null,
                            });
                          }
                          setData({
                            ...data,
                            isPakkiSamitiMember: false,
                            pakkiSamiti: null,
                          });
                        }}
                        className="px-4 py-2 mr-2 transition duration-300 border border-gray-300 rounded w-fit focus:border-transparent focus:outline-none focus:ring-gray-300"
                      />
                      <label className="text-sm capitalize ">No</label>
                    </span>
                    <span>
                      <input
                        type="radio"
                        name="isPakkiSamitiMember"
                        value="yes"
                        checked={data.isPakkiSamitiMember}
                        onChange={() => {
                          if (!isNewRecord) {
                            setUpdateData({
                              ...updateData,
                              isPakkiSamitiMember: true,
                            });
                          }
                          setData({
                            ...data,
                            isPakkiSamitiMember: true,
                          });
                        }}
                        className="px-4 py-2 mr-2 transition duration-300 border border-gray-300 rounded w-fit focus:border-transparent focus:outline-none focus:ring-gray-300"
                      />
                      <label className="text-sm capitalize">Yes</label>
                    </span>
                  </div>
                </div>
                {/* If you already the member of Pakki Sammitti, Please specify the name of Sammitti. */}
                {data.isPakkiSamitiMember && (
                  <div className={`flex flex-col space-y-1`}>
                    <div className="flex flex-col w-full mb-0 space-y-1 md:p-1">
                      <label
                        htmlFor="pakki-sammitti-name"
                        className="text-sm capitalize required"
                      >
                        If you already the member of Pakki Sammitti, Please
                        specify the name of Sammitti.
                      </label>
                      <select
                        className="w-full px-4 py-2 text-black bg-transparent border border-gray-300 rounded-md outline-none"
                        name="pakkiSamiti"
                        value={data.pakkiSamiti}
                        onChange={(e) => {
                          e.target.value === "Other"
                            ? setIsPakkiSamitiOther(true)
                            : setIsPakkiSamitiOther(false);
                          if (!isNewRecord) {
                            handleUpdateChange(e);
                          }
                          handleInputChange(e);
                        }}
                      >
                        <option value="" defaultValue hidden>
                          --Select--
                        </option>
                        {sammittiArray.sort().map((samiti) => {
                          return (
                            <option value={samiti.label} key={samiti.value}>
                              {samiti.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
                {/* 	Pakki samiti  */}
                {isPakkiSamitiOther && (
                  <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                    <label
                      htmlFor="currentCompany"
                      className="text-sm text-gray-500 capitalize required"
                    >
                      Pakki Samiti
                    </label>

                    <input
                      type="text"
                      name="pakkiSamiti"
                      value={data.pakkiSamiti}
                      onChange={(e) => {
                        if (!isNewRecord) {
                          handleUpdateChange(e);
                        }
                        handleInputChange(e);
                      }}
                      className="w-full px-3 py-2 transition duration-300 bg-transparent border border-gray-300 rounded peer sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                    />
                  </div>
                )}
                {/* naam date */}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <label
                    htmlFor="sewadarSince"
                    className={`text-sm text-gray-500 capitalize ${
                      !isCategoryC && "required"
                    }`}
                  >
                    Naam Year
                  </label>

                  <input
                    type="text"
                    name="naamDate"
                    maxLength={4}
                    value={data.naamDate}
                    onChange={(e) => {
                      if (e.target.value * 1 > yyyy) {
                        if (!isNewRecord) {
                          setUpdateData({
                            ...updateData,
                            naamDate: yyyy,
                          });
                        }

                        setData({ ...data, naamDate: yyyy });
                      } else {
                        if (!isNewRecord) {
                          setUpdateData({
                            ...updateData,
                            naamDate: e.target.value,
                          });
                        }
                        setData({ ...data, naamDate: e.target.value });
                      }
                    }}
                    className="w-full px-3 py-2 transition duration-300 bg-transparent border border-gray-300 rounded peer sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  />
                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    max year should be {yyyy}
                  </p>
                </div>
                {/* jaam date */}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <label
                    htmlFor="sewadarSince"
                    className="text-sm text-gray-500 capitalize "
                  >
                    Jaam Year
                  </label>

                  <input
                    type="text"
                    name="jaamDate"
                    maxLength={4}
                    value={data.jaamDate}
                    onChange={(e) => {
                      if (e.target.value * 1 > yyyy) {
                        if (!isNewRecord) {
                          setUpdateData({
                            ...updateData,
                            jaamDate: yyyy,
                          });
                        }

                        setData({ ...data, jaamDate: yyyy });
                      } else {
                        if (!isNewRecord) {
                          setUpdateData({
                            ...updateData,
                            jaamDate: e.target.value,
                          });
                        }
                        setData({ ...data, jaamDate: e.target.value });
                      }
                    }}
                    className="w-full px-3 py-2 transition duration-300 bg-transparent border border-gray-300 rounded peer sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  />
                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    max year should be {yyyy}
                  </p>
                </div>
                {/* Green S Welfare member */}
                <div className="flex flex-col space-y-1">
                  <label
                    htmlFor="sewa-memeber"
                    className={`text-sm capitalize ${!isCategoryC && "required"}`}
                  >
                    Green S Welfare Sewadaar
                  </label>
                  <div className="flex gap-2">
                    <span>
                      <input
                        type="radio"
                        name="uniformNumber"
                        value=""
                        checked={!showGreenSwefareFiled}
                        onChange={() => {
                          setShowGreenSwefareFiled(false);
                          if (!isNewRecord) {
                            setUpdateData({
                              ...updateData,
                              uniformNumber: "",
                            });
                          }
                          setData({ ...data, uniformNumber: "" });
                        }}
                        className="px-4 py-2 mr-2 transition duration-300 border border-gray-300 rounded w-fit focus:border-transparent focus:outline-none focus:ring-gray-300"
                      />
                      <label className="text-sm capitalize ">No</label>
                    </span>
                    <span>
                      <input
                        type="radio"
                        checked={showGreenSwefareFiled}
                        onChange={() => setShowGreenSwefareFiled(true)}
                        className="px-4 py-2 mr-2 transition duration-300 border border-gray-300 rounded w-fit focus:border-transparent focus:outline-none focus:ring-gray-300"
                      />
                      <label className="text-sm capitalize">Yes</label>
                    </span>
                  </div>
                </div>
                {/* uniform date */}
                {showGreenSwefareFiled && (
                  <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                    <label
                      htmlFor="sewadarSince"
                      className="text-sm text-gray-500 capitalize"
                    >
                      Green S Wefare Uniform
                    </label>

                    <input
                      type="text"
                      name="uniformNumber"
                      maxLength={6}
                      value={data.uniformNumber}
                      onChange={(e) => {
                        if (!isNewRecord) {
                          setUpdateData({
                            ...updateData,
                            uniformNumber: e.target.value,
                          });
                        }

                        setData({ ...data, uniformNumber: e.target.value });
                      }}
                      className="w-full px-3 py-2 transition duration-300 bg-transparent border border-gray-300 rounded peer sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                    />
                    <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                      max year should be {yyyy}
                    </p>
                  </div>
                )}
              </form>
            </div>
          </div>
          <div className="py-1 my-5 bg-white rounded-lg">
            <ButtonsLayout
              currentTab={currentTab}
              changeTab={changeTab}
              data={data}
              showSaveButton={isNewRecord}
              updateData={updateData}
              setShowSuccessModal={setShowSuccessModal}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SewaDetails;
