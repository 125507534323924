import React, {useEffect, useState} from 'react';
import Spinner from '../../components/Spinner';
import StatCard from '../../components/StatCard';
import {errorToast} from '../../shared/Service';
import {API, formateDate} from '../../shared/utility';

const Stats = () => {
	const getFormatedDate = (date) => {
		let today = new Date(date);
		let dd = String(today.getDate()).padStart(2, '0');
		let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		let yyyy = today.getFullYear();

		today = yyyy + '-' + mm + '-' + dd;
		return today;
	};
	const [stats, setStats] = useState({
		totalUsersTillDate: 0,
		totalAdminsTillDate: 0,
		totalUsersCreatedToday: 0,
		totalAdminsCreatedToday: 0,
	});
	const [startDate, setStartDate] = useState(getFormatedDate(new Date()));
	const [endDate, setEndDate] = useState(getFormatedDate(new Date()));
	const [stateWiseCount, setStateWiseCount] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isStatsLoading, setIsStatsLoading] = useState(false);
	const [days, setDays] = useState(1);
	// const [selectedUserType, setSelectedUserType] = useState('users');

	const queryStringStats = ({days}) => {
		var query = '?';

		if (days) query = `${query}days=${days}&`;

		return query.substring(0, query.length - 1);
	};

	const handleLoadStats = async () => {
		setIsStatsLoading(true);
		const query = queryStringStats({
			days: days,
		});

		API.GET_STATS(query)
			.then((res) => {
				setStats(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				errorToast(err.response.data.message);
			})
			.finally(() => setIsStatsLoading(false));
	};

	const queryString = ({startDate, endDate, selectedUserType}) => {
		var query = '?';
		if (startDate) query = `${query}start=${formateDate(startDate)}&`;
		if (endDate) query = `${query}end=${formateDate(endDate)}&`;
		// if (selectedUserType) query = `${query}user=${selectedUserType}&`;

		return query.substring(0, query.length - 1);
	};

	const handleLoadUsers = async () => {
		setIsLoading(true);

		const query = queryString({
			startDate: startDate,
			endDate: endDate,
			// selectedUserType: selectedUserType,
		});

		API.GET_STATEWISE_COUNT_LIST(query)
			.then((res) => {
				setStateWiseCount(res?.data?.data);
				console.log(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				errorToast(err.response.data.message);
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		handleLoadStats();
		handleLoadUsers();
	}, [0]);

	return (
		<div className="min-h-screen py-6 bg-blue-100">
			<div className="py-4 m-auto w-[90%] md:max-w-6xl px-4  bg-white shadow-md shadow-gray-300 rounded-md mt-10">
				{/* stats cards */}
				<div className="flex flex-col items-center justify-between space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
					<StatCard title={`Total User's`} count={stats.totalUsersTillDate} loading={isStatsLoading} />
					<StatCard title={'Total Admins'} count={stats.totalAdminsTillDate} loading={isStatsLoading} />
					<StatCard title={`User's created Today`} count={stats.totalUsersCreatedToday} loading={isStatsLoading} />
					<StatCard title={`Admin created Today`} count={stats.totalAdminsCreatedToday} loading={isStatsLoading} />
				</div>

				<p className="mt-8 text-xl text-gray-700 md:text-2xl capitalize">State Wise User's Count</p>
				<div className="flex flex-wrap items-end">
					{/* Start Date*/}
					<div className="flex flex-col w-[90%] md:w-[20%] m-auto md:m-2 space-y-1 my-2">
						<label htmlFor="startDate" className="text-sm text-gray-400">
							Start Date
						</label>
						<input
							type="date"
							name="startDate"
							value={startDate}
							min="2022-10-01"
							max={getFormatedDate(new Date())}
							onChange={(e) => setStartDate(e.target.value)}
							className="px-4 py-2 text-[#978D88] transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200 "
						/>
					</div>
					{/* End Date*/}
					<div className="flex flex-col w-[90%] md:w-[20%] m-auto md:m-2 space-y-1 my-2">
						<label htmlFor="endDate" className="text-sm text-gray-400">
							End Date
						</label>
						<input
							type="date"
							name="endDate"
							value={endDate}
							min={getFormatedDate(startDate)}
							max={getFormatedDate(new Date())}
							onChange={(e) => setEndDate(e.target.value)}
							minDate={new Date(startDate)}
							className="px-4 py-2 text-[#978D88] transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200  "
						/>
					</div>
					{/* user Type */}
					{/* <div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 space-y-1 my-2">
						<label htmlFor="onlyApproved" className="text-sm  text-gray-400">
							User Type
						</label>
						<select
							className="text-black px-3 sm:px-4 py-2  border    border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200"
							name="onlyApproved"
							value={selectedUserType}
							onChange={(e) => {
								setSelectedUserType(e.target.value);
							}}>
							<option value="users" defaultChecked selected={true}>
								Users
							</option>
							<option value="admins">Admins</option>
						</select>
					</div> */}

					<div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 my-2">
						<button className="p-2 px-4 text-white bg-blue-500 rounded-md shadow-sm shadow-gray-700" onClick={handleLoadUsers}>
							Search
						</button>
					</div>
				</div>
				<div className="overflow-auto py-4">
					{stateWiseCount && (
						<div className="flex flex-col items-center justify-between space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
							<StatCard title={`Total Users Created`} count={stateWiseCount.totalUsersCreated} loading={isStatsLoading} />
							<StatCard title={`Total Admins Created`} count={stateWiseCount.totalAdminsCreated} loading={isStatsLoading} />
						</div>
					)}
					<table className="min-w-full my-4 bg-blue-100 divide-y divide-white rounded-lg shadow-md table-fixed shadow-gray-300">
						<thead>
							<tr>
								{heading &&
									heading.map((tr) => {
										return (
											<th scope="col" className="p-3 text-xs font-semibold  text-left text-gray-500 uppercase md:px-6 md:py-3 whitespace-nowrap">
												{tr}
											</th>
										);
									})}
							</tr>
						</thead>
						<tbody className="divide-y divide-white">
							{isLoading ? (
								<td colSpan={8}>
									<Spinner css={'my-10'} />
								</td>
							) : (
								stateWiseCount?.stateWiseCount?.length > 0 &&
								stateWiseCount?.stateWiseCount?.map((state, index) => {
									return (
										<tr key={index} className="hover:bg-blue-200">
											<td className={tdClass}>{index + 1}</td>
											<td className={`${tdClass} cursor-pointer`}>{state.state}</td>
											<td className={`${tdClass}  cursor-pointer`}>{state.count}</td>
											{/* <td className={tdClass}>
												<button
													className="p-2 px-4 text-sm text-white bg-blue-500 rounded-md shadow-sm shadow-gray-700"
													// onClick={() => {}}
												>
													View
												</button>
											</td> */}
										</tr>
									);
								})
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

const tdClass = 'md:px-6 md:py-4 p-3 text-left text-sm text-gray-800 whitespace-nowrap capitalize  ';
const heading = [
	'S.No',
	'State',
	'Count',
	// 'Action'
];
export default Stats;
