import React, { useEffect, useState } from "react";
import { qualificationArray } from "../../shared/data";
import { errorToast } from "../../shared/Service";
import { API } from "../../shared/utility";
import ButtonsLayout from "../ButtonsLayout";
import Spinner from "../Spinner";

const EducationDetails = ({
  currentTab,
  changeTab,
  setShowSuccessModal,
  isUpdating,
}) => {
  const [data, setData] = useState({
    highestEducation: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(true);
  const [updateData, setUpdateData] = useState({});
  const [isEduOth, setIsEduOth] = useState(false);
  const isCategoryC = JSON.parse(localStorage.getItem("isCategoryC"));

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleUpdateChange = (event) => {
    setUpdateData({ ...updateData, [event.target.name]: event.target.value });
  };

  const GetEducationDetails = async () => {
    const userId = localStorage.getItem("userId");

    if (userId) {
      setIsLoading(true);
      API.GET_EDUCATION_DETAIL(userId)
        .then((res) => {
          res?.data?.data?.length === 0
            ? setIsNewRecord(true)
            : setIsNewRecord(false);
          const { highestEducation } = res.data.data;

          const educationDetails = {
            highestEducation,
          };
          setData(educationDetails);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    GetEducationDetails();
  }, []);

  return (
    <div className={`${currentTab === 1 ? "block" : "hidden"}`}>
      {isLoading ? (
        <div className="w-full h-[50vh] flex items-center justify-center bg-white  rounded-lg">
          <Spinner />
        </div>
      ) : (
        <>
          <div className={`w-full flex-col bg-white  rounded-lg flex`}>
            <div className="p-3 sm:p-5">
              {/* Ex-student Detai ls */}
              <div className="md:flex-1">
                <form action="#" className="flex flex-col space-y-2">
                  {/* highest quaification*/}

                  <div className="w-full flex flex-col space-y-1 md:p-1 mb-0">
                    <label
                      htmlFor="highestEducation"
                      className={`text-sm ${!isCategoryC && "required"}`}
                    >
                      What is your Highest Qualification?
                    </label>
                    <select
                      className="text-black px-4 w-full py-2 border-gray-300 border rounded-md bg-transparent outline-none"
                      name="highestEducation"
                      value={data.highestEducation}
                      onChange={(e) => {
                        e.target.value === "other"
                          ? setIsEduOth(true)
                          : setIsEduOth(false);
                        if (!isNewRecord) {
                          handleUpdateChange(e);
                        }
                        handleInputChange(e);
                      }}
                    >
                      <option
                        value=""
                        defaultValue
                        hidden
                        className="text-gray-200"
                      >
                        {isEduOth
                          ? "Other "
                          : data.highestEducation !== null
                          ? qualificationArray.includes(
                              data.highestEducation
                            ) && data.highestEducation !== null
                            ? data.highestEducation
                            : "Other" && setIsEduOth(true)
                          : "-Select-"}
                      </option>
                      {qualificationArray.map((qualificationName, index) => {
                        return (
                          <option
                            value={qualificationName}
                            key={index}
                            selected={
                              data.highestEducation === qualificationName
                            }
                          >
                            {qualificationName}
                          </option>
                        );
                      })}
                      <option
                        value="other"
                        selected={
                          !qualificationArray.includes(data.highestEducation) &&
                          data.highestEducation !== null
                        }
                      >
                        Other
                      </option>
                    </select>
                  </div>

                  {/*  other Educatiojn field */}
                  {isEduOth && (
                    <div className={`flex flex-col space-y-1`}>
                      <label
                        htmlFor="currentProfession"
                        className="text-sm  required "
                      >
                        Name of Education Degree
                      </label>
                      <input
                        type="text"
                        name="highestEducation"
                        value={
                          data.highestEducation === "other"
                            ? setData({ ...data, highestEducation: "" })
                            : data.highestEducation
                        }
                        onChange={(e) => {
                          if (!isNewRecord) {
                            handleUpdateChange(e);
                          }
                          handleInputChange(e);
                        }}
                        className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                      />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
          <div className="my-5 py-1  bg-white  rounded-lg">
            <ButtonsLayout
              currentTab={currentTab}
              changeTab={changeTab}
              data={data}
              showSaveButton={isNewRecord}
              updateData={updateData}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EducationDetails;
