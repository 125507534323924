import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import LoginImg from '../assets/images/Secure.svg';
import PrimaryButton from '../components/PrimaryButton';
import Spinner from '../components/Spinner';
import {BASEURL, COMMON_URL, errorToast, successToast} from '../shared/Service';

const Login = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [authToken, setAuthToken] = useState();
	const [loginObj, setLoginObj] = useState({
		email: '',
		password: '',
	});

	const [showPassword, setShowPassword] = useState(false);

	// 	// if (loginObj.email.length < 7 || loginObj.phoneNo.length > 13) return true;

	// 	return false;
	// };

	const handleInputChange = (event) => {
		setLoginObj({...loginObj, [event.target.name]: event.target.value});
	};

	//Hit Login api
	const handleLoginClick = async (event) => {
		event.preventDefault();
		setIsLoading(true);

		axios
			.post(BASEURL + '/admin/login', loginObj)
			.then((res) => {
				if (res.data?.data) {
					localStorage.clear();
					const response = res?.data?.data;

					localStorage.setItem('Authtoken', response?.token);
					localStorage.setItem('userInfo', JSON.stringify(response?.admin));

					setAuthToken(response?.token);

					if (response?.admin?.role === 'superadmin') {
						successToast('Login Successfull !!');
						navigate('/');
						window.location.reload();
					} else if (response?.admin?.role === 'admin') {
						successToast('Login Successfull !!');
						navigate('/');
						window.location.reload();
					} else {
						errorToast('Inavlid Credentials!');
					}
				}
				setIsLoading(false);
			})
			.catch((e) => {
				setIsLoading(false);
				errorToast(e.response.data.message);
			});
	};

	return (
		<div className="flex flex-col items-center justify-center min-h-screen bg-blue-100 ">
			<div className="w-[90%] md:w-[900px] shadow-sm">
				{isLoading ? (
					<div className="w-full h-[50vh] flex items-center justify-center">
						<Spinner />
					</div>
				) : (
					<>
						<p className="mb-5 text-2xl font-bold text-gray-600 md:text-3xl">Account Login</p>
						<div className="items-center p-4 m-auto bg-white shadow-gray-300 md:p-8 md:flex">
							<div className="w-[90%] md:m-auto mb-4 mx-auto order-1 md:w-2/4">
								<img src={LoginImg} className="w-[60%] m-auto" alt="" />
							</div>
							<div className="w-[90%] m-auto order-2 md:w-2/4">
								<div className="flex flex-col my-2 space-y-1">
									<label htmlFor="email" className="text-sm text-gray-400">
										Email address
									</label>
									<input
										type="email"
										name="email"
										onChange={handleInputChange}
										autoFocus
										value={loginObj.email}
										className="px-4 py-2 text-gray-400 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200"
									/>
									<p className="hidden mt-2 text-sm peer-invalid:block text-red"> invalid email address.</p>
								</div>
								{/*  password */}
								<div className="flex flex-col my-2 space-y-1">
									<label htmlFor="password" className="text-sm text-gray-400">
										Password
									</label>

									<input
										type={showPassword ? 'text' : 'password'}
										name="password"
										onChange={handleInputChange}
										value={loginObj.password}
										className="px-4 py-2 text-gray-500 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-200"
									/>
								</div>
								{/* show pass checkbox */}
								<div className="flex w-full mt-4 space-x-2">
									<input
										type="checkbox"
										id="remember"
										className="w-4 h-4 transition duration-300 rounded accent-gray-500 focus:ring-2 focus:ring-offset-0 focus:outline-none focus:ring-gray-200"
										onChange={(e) => {
											e.target.checked ? setShowPassword(true) : setShowPassword(false);
										}}
									/>
									<div className="flex items-center justify-between w-full">
										<label htmlFor="remember" className="text-sm text-gray-500">
											Show Password
										</label>
									</div>
								</div>

								{/* {isLoading && <FullScreenSpinner />} */}
								<PrimaryButton disable={false} action={handleLoginClick} text="Login" bg="bg-blue-500" />
								<div className="flex justify-between">
									{/* <span className="text-blue-500 cursor-pointer hover:underline">
										<Link to="/send-email">Forget Password?</Link>
									</span> */}
									{/* <span className='text-blue-500 cursor-pointer hover:underline'>
										<Link to='/register'>Register now</Link>
									</span> */}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Login;
