import React from 'react';
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi';

const Pagination = ({totalDocuments, size, setPage, page}) => {
	const totalPageCount = Math.ceil(totalDocuments / size);
	// console.log(totalPageCount)

	const handlePrevious = () => {
		if (page !== 1) {
			setPage(page - 1);
		}
	};

	const handleNext = () => {
		if (page !== totalPageCount) {
			setPage(page + 1);
		}
	};

	return (
		<div className="flex flex-col items-center justify-between px-4 space-x-4 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-0">
			<div className="text-sm">
				Showing {1 + (page - 1) * 10} to {totalDocuments < size ? totalDocuments : size * page} of {totalDocuments} entries
			</div>
			<div className="flex flex-row items-center justify-end flex-nowrap">
				<span className={`flex w-8 h-8 sm:w-10 sm:h-10 mr-1 justify-center items-center text-black ${page === 1 ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={handlePrevious}>
					<FiChevronLeft />
				</span>
				{totalPageCount &&
					[...Array(totalPageCount)].map((item, index) => {
						return (
							<span
								key={index}
								className={`flex w-8 h-8 sm:w-10 sm:h-10 text-sm sm:text-base mx-1 justify-center items-center rounded-md border border-gray-200  hover:border-gray-300 cursor-pointer ${
									page === index + 1 ? `bg-blue-500 text-white hover:bg-blue-400` : `bg-white text-gray-600`
								}`}
								onClick={() => {
									setPage(index + 1);
								}}>
								{index + 1}
							</span>
						);
					})}

				<span className={`flex w-8 h-8 sm:w-10 sm:h-10 mr-1 justify-center items-center text-black ${page === totalPageCount ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={handleNext}>
					<FiChevronRight />
				</span>
			</div>
		</div>
	);
};

export default Pagination;
