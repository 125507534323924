import React, {useEffect, useState} from 'react';
import {FaBlackTie, FaBookOpen, FaPrayingHands, FaUserAlt} from 'react-icons/fa';
import {useParams} from 'react-router-dom';
import EducationalDetails from '../components/details/EducationDetails';
import PersonalDetails from '../components/details/PersonalDetails';
import ProfessionalDetails from '../components/details/ProfessionalDetails';
import SewaDetails from '../components/details/SewaDetails';
import './styles/Form.css';

import {useNavigate} from 'react-router-dom';

const Details = () => {
	const navigate = useNavigate();
	const [currentTab, setCurrentTab] = useState(0);
	const changeTab = (name) => setCurrentTab(name);
	const isUpdating = window.location.pathname.split('/')[1] === 'update-entry';
	const {id} = useParams();

	const getCurrentForm = () => {
		switch (currentTab) {
			case 0:
				return <PersonalDetails currentTab={currentTab} changeTab={changeTab} isUpdating={isUpdating} userId={id} />;
			case 1:
				return <EducationalDetails currentTab={currentTab} changeTab={changeTab} isUpdating={isUpdating} userId={id} />;
			case 2:
				return <ProfessionalDetails currentTab={currentTab} changeTab={changeTab} isUpdating={isUpdating} userId={id} />;
			case 3:
				return <SewaDetails currentTab={currentTab} changeTab={changeTab} isUpdating={isUpdating} userId={id} />;
			default:
				return <PersonalDetails currentTab={currentTab} changeTab={changeTab} isUpdating={isUpdating} userId={id} />;
		}
	};
	const wrapperDivClass = `flex items-center text-gray-600  relative`;
	const iconOutter = `rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-400 text-gray-600 flex justify-center items-center`;
	const stepperName = `absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-medium uppercase text-gray-600`;

	useEffect(() => {
		const userDetails = JSON.parse(localStorage.getItem('userInfo'));
		if (userDetails?.user?.role === 'superadmin') {
			navigate('/admin/all-admin');
		}
	}, []);

	return (
		<div className="w-full min-h-screen py-2 bg-blue-100 rounded-md shadow">
			{/* <!-- stepper frontend only --> */}

			<div className="max-w-6xl p-2 pt-8 mx-auto sm:p-5 ">
				<div className="p-4 pb-8 mb-5 bg-white rounded-lg shadow-md shadow-gray-300">
					<div className="flex items-center">
						<div
							className={wrapperDivClass}
							// onClick={() => setCurrentTab(0)}
							//To-Be-Removed
						>
							<div className={`${iconOutter} ${currentTab > 0 && `bg-blue-500 border-none text-white}`}  ${currentTab === 0 && `border-blue-500 `}  `}>
								<FaUserAlt className={`${currentTab > 0 && `text-white`}`} />
							</div>
							<div className={`${stepperName} hidden md:block`}>Personal</div>
						</div>

						<div className={`flex-auto border-t-2 transition duration-500 ease-in-out ${currentTab > 0 && `border-blue-500`}`}></div>

						<div
							className={wrapperDivClass}
							// onClick={() => setCurrentTab(1)}
							//To-Be-Removed
						>
							<div className={`${iconOutter} ${currentTab > 1 && `bg-blue-500 border-none text-white}`}  ${currentTab === 1 && `border-blue-500 `}  `}>
								<FaBookOpen className={`${currentTab > 1 && `text-white`}`} />
							</div>
							<div className={`${stepperName} hidden md:block`}>Education</div>
						</div>

						<div className={`flex-auto border-t-2 transition duration-500 ease-in-out ${currentTab > 1 && `border-blue-500`}`}></div>

						<div
							className={wrapperDivClass}
							// onClick={() => setCurrentTab(2)}
							//To-Be-Removed
						>
							<div className={`${iconOutter} ${currentTab > 2 && `bg-blue-500 border-none text-white}`}  ${currentTab === 2 && `border-blue-500 `}  `}>
								<FaBlackTie className={`${currentTab > 2 && `text-white`}`} />
							</div>
							<div className={`${stepperName} hidden md:block`}>Professional</div>
						</div>

						<div className={`flex-auto border-t-2 transition duration-500 ease-in-out ${currentTab > 2 && `border-blue-500`}`}></div>

						<div
							className={wrapperDivClass}
							// onClick={() => setCurrentTab(3)}
							//To-Be-Removed
						>
							<div className={`${iconOutter} ${currentTab > 3 && `bg-blue-500 border-none text-white}`}  ${currentTab === 3 && `border-blue-500 `}  `}>
								<FaPrayingHands className={`${currentTab > 3 && `text-white`}`} />
							</div>
							<div className={`${stepperName} hidden md:block`}>Welfare</div>
						</div>
					</div>
				</div>
				<hr className="my-2" />

				{getCurrentForm()}
			</div>
		</div>
	);
};

export default Details;
