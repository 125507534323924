import React, { useState } from "react";
import { errorToast, successToast } from "../shared/Service";
import { API } from "../shared/utility";
import Spinner from "./Spinner";

const ButtonsLayout = ({
  currentTab,
  changeTab,
  data,
  showSaveButton,
  updateData,
  currentProfessionOther,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isCategoryC = JSON.parse(localStorage.getItem("isCategoryC"));
  console.log(isCategoryC)

  const handlePrevious = () => {
    setIsLoading(false);
    switch (currentTab) {
      case 1:
        return changeTab(0);
      case 2:
        return changeTab(1);
      case 3:
        return changeTab(2);
      default:
        return changeTab(0);
    }
  };
  const handleNext = () => {
    switch (currentTab) {
      case 0:
        return changeTab(1);
      case 1:
        return changeTab(2);
      case 2:
        return changeTab(3);
      default:
        return changeTab(0);
    }
  };
  const handleSave = (addRelatedAcc) => {
    switch (currentTab) {
      case 0:
        return addPersonalDetails();
      case 1:
        return addEducationDetails();
      case 2:
        return addProfessionalDetails();
      case 3:
        return addWelfareDetials(addRelatedAcc);
      default:
        return alert("Please Refresh And Try Agian");
    }
  };

  const handleUpdate = () => {
    if (JSON.stringify(updateData) === "{}") {
      handleNext();
      return;
    }
    //console.log(updateData);
    switch (currentTab) {
      case 0:
        return JSON.stringify(updateData) !== "{}" && UPDATE_PersonalDetails();
      case 1:
        return JSON.stringify(updateData) !== "{}" && UPDATE_EducationDetails();
      case 2:
        return (
          JSON.stringify(updateData) !== "{}" && UPDATE_ProfessionalDetails()
        );
      case 3:
        return JSON.stringify(updateData) !== "{}" && UPDATE_WelfareDetials();
      default:
        return alert("Please Refresh And Try Agian");
    }
  };

  var PhoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  // api
  const addPersonalDetails = async () => {
    const {
      parentId,
      relativeName,
      relation,
      name,
      gender,
      callingPhoneNo,
      whatsappPhoneNo,
      dob,
      fatherName,
      country,
      state,
      district,
      caste,
    } = data;

    let personalDetails = data;

    if (parentId || relativeName) {
      if (
        !relation ||
        relation === "" ||
        relation == null ||
        relation === undefined
      ) {
        errorToast("Please enter valid relation!!");

        return;
      }
    }

    if (!name) {
      errorToast("Please enter valid Name  !!");

      return;
    }

    if (!fatherName) {
      errorToast("Please enter valid Father Name  !! ");
      return;
    }
    if (!dob && !data.cCategory) {
      errorToast("Please enter valid Date of birth  !! ");
      return;
    }

    if (gender === "" || gender === null) {
      errorToast("Please select valid gender  !! ");
      return;
    }

    if (!PhoneRegex.test(callingPhoneNo)) {
      errorToast("Please enter valid Phone Number !! ");
      return;
    }
    if (whatsappPhoneNo && !PhoneRegex.test(whatsappPhoneNo)) {
      errorToast("Please enter valid Whatsapp Number !! ");
      return;
    }

    if (!country) {
      errorToast("Please enter country!!");
      return;
    }
    if (!state) {
      errorToast("Please enter state!!");
      return;
    }
    if (!district) {
      errorToast("Please enter district!!");
      return;
    }

    if (!caste) {
      errorToast("Please enter caste!!");
      return;
    }
    setIsLoading(true);

    API.POST_PERSONAL_DETAIL(personalDetails)
      .then((res) => {
        if (res.status === 200) {
          successToast("Data Save  ");
          res?.data?.data?.selfUserId &&
            localStorage.setItem("userId", res.data.data.selfUserId);
          handleNext();
        }
      })
      .catch((err) => {
        errorToast("oops! something went wrong!");
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };
  const addEducationDetails = async () => {
    const { highestEducation } = data;

	console.log(highestEducation)

    if (!highestEducation && !isCategoryC) {
      errorToast("Please Enter Highest Educ. Details");
      return;
    }

    setIsLoading(true);

    API.POST_EDUCATION_DETAIL(localStorage.getItem("userId"), data)
      .then((res) => {
        if (res.status === 200) {
          successToast("Data Save  ");
          handleNext();
        }
      })
      .catch((err) => {
        errorToast("oops! something went wrong!");
        console.log(
          err?.response?.data?.message
            ? err.response.data.message
            : "oops! something went wrong!"
        );
      })
      .finally(() => setIsLoading(false));
  };
  const addProfessionalDetails = async () => {
    const { currentProfession, sector, occupationName, designation } = data;

    if (!currentProfession && !isCategoryC) {
      errorToast("Please enter valid profession!");
      return;
    }
    if (
      (currentProfession == "private" ||
        currentProfession == "government" ||
        currentProfession == "businessman") &&
      !sector
    ) {
      errorToast("Please enter valid sector!");
      return;
    }

    if (
      (currentProfession == "private" || currentProfession == "government") &&
      !designation
    ) {
      errorToast("Please enter designation!");
      return;
    }

    if (currentProfession == "businessman" && !occupationName) {
      errorToast("Please enter valid Occupation Name!");
      return;
    }

    if (currentProfession == "other" && !currentProfessionOther) {
      errorToast("Please enter name of profession!");
      return;
    }

    let professionalDetails = data;

    if (currentProfession == "other") {
      professionalDetails["currentProfession"] = currentProfessionOther;
    }
    setIsLoading(true);
    API.POST_PROFESSION_DETAIL(localStorage.getItem("userId"), data)
      .then((res) => {
        if (res.status === 200) {
          successToast("Data Save  ");
          handleNext();
        }
      })
      .catch((err) => {
        errorToast(
          err?.response?.data?.message
            ? err.response.data.message
            : "oops! something went wrong!"
        );
        console.log(
          err?.response?.data?.message ? err.response.data.message : err.message
        );
      })
      .finally(() => setIsLoading(false));
  };
  const addWelfareDetials = async (addRelatedDetails) => {
    const { isPakkiSamitiMember, pakkiSamiti, naamDate } = data;

    if (isPakkiSamitiMember && !pakkiSamiti) {
      errorToast("Please Select sewa sammitti Name!");
      return;
    }

    if (naamDate * 1 < 1947 || naamDate * 1 > 2022) {
      errorToast("Please enter valid Naam Date!");
      return;
    }

    setIsLoading(true);
    API.POST_WELFARE_DETAIL(localStorage.getItem("userId"), data)
      .then((res) => {
        if (res.status === 200) {
          const {
            name,
            block,
            district,
            state,
            country,
            caste,
            village,
            vehicle,
            address,
          } = res.data.data;
          if (addRelatedDetails) {
            localStorage.setItem("parentId", res.data.data.userId);
            localStorage.setItem(
              "selfDetails",
              JSON.stringify({
                name: name,
                block: block,
                state: state,
                country: country,
                caste: caste,
                district: district,
                village: village,
                vehicle: vehicle,
                address: address,
              })
            );
            localStorage.setItem("selfFamilyId", res.data.data.familyId);
          } else {
            localStorage.removeItem("parentId");
            localStorage.removeItem("selfDetails");
            localStorage.removeItem("selfFamilyId");
          }

          localStorage.removeItem("userToken");
          localStorage.removeItem("userId");
          localStorage.removeItem("isCategoryC");

          successToast("Data Save ");
          changeTab(0);
        }
      })
      .catch((err) => {
        errorToast("oops! something went wrong!");
        console.log(
          err?.response?.data?.message ? err.response.data.message : err.message
        );
      })
      .finally(() => setIsLoading(false));
  };

  const UPDATE_PersonalDetails = async () => {
    const {
      userId,
      parentId,
      familyId,
      relativeName,
      relation,
      name,
      gender,
      callingPhoneNo,
      whatsappPhoneNo,
      occupationName,
      dob,
      fatherName,
      country,
      state,
      district,
      village,
      block,
      bloodGroup,
      caste,
      vehicle,
      politicalParty,
      socialDesignation,
      knownOfficers,
    } = data;

    if (!name) {
      errorToast("Please enter valid Name  !!");

      return;
    }

    if (!fatherName) {
      errorToast("Please enter valid Father Name  !! ");
      return;
    }
    if (!dob && !data.cCategory) {
      errorToast("Please enter valid Date of birth  !! ");
      return;
    }

    if (gender === "" || gender === null) {
      errorToast("Please enter valid gender  !! ");
      return;
    }

    if (!PhoneRegex.test(callingPhoneNo)) {
      errorToast("Please enter valid Phone Number !! ");
      return;
    }
    if (whatsappPhoneNo && !PhoneRegex.test(whatsappPhoneNo)) {
      errorToast("Please enter valid Whatsapp Number !! ");
      return;
    }

    if (!country) {
      errorToast("Please enter country!!");
      return;
    }
    if (!state) {
      errorToast("Please enter state!!");
      return;
    }
    if (!district) {
      errorToast("Please enter district!!");
      return;
    }

    if (!caste) {
      errorToast("Please enter caste!!");
      return;
    }

    setIsLoading(true);
    API.PUT_PERSONAL_DETAIL(userId, updateData)
      .then((res) => {
        if (res.status === 200) {
          successToast("Data Updated  ");

          handleNext();
        }
      })
      .catch((err) => {
        errorToast(
          err?.response?.data?.message
            ? err.response.data.message
            : "oops! something went wrong!"
        );
        console.log(
          err?.response?.data?.message ? err.response.data.message : err.message
        );
      })
      .finally(() => setIsLoading(false));
  };
  const UPDATE_EducationDetails = async () => {
    const userId = localStorage.getItem("userId");
    const { highestEducation } = data;

    if (!highestEducation && !isCategoryC) {
      errorToast("Please Enter Highest Educ. Details");
      return;
    }

    setIsLoading(true);

    API.PUT_EDUCATION_DETAIL(userId, updateData)
      .then((res) => {
        if (res.status === 200) {
          successToast("Data Updated  ");
          handleNext();
        }
      })
      .catch((err) => {
        errorToast("oops! something went wrong!");
        console.log(
          err?.response?.data?.message
            ? err.response.data.message
            : "oops! something went wrong!"
        );
      })
      .finally(() => setIsLoading(false));
  };
  const UPDATE_ProfessionalDetails = async () => {
    const { currentProfession, sector, occupationName, designation } = data;
    const userId = localStorage.getItem("userId");
    if (!currentProfession && !isCategoryC) {
      errorToast("Please enter valid profession!");
      return;
    }
    if (
      (currentProfession == "private" ||
        currentProfession == "government" ||
        currentProfession == "businessman") &&
      !sector
    ) {
      errorToast("Please enter valid sector!");
      return;
    }

    if (
      (currentProfession == "private" || currentProfession == "government") &&
      !designation
    ) {
      errorToast("Please enter designation!");
      return;
    }

    if (currentProfession == "businessman" && !occupationName) {
      errorToast("Please enter valid Occupation Name!");
      return;
    }

    setIsLoading(true);

    let payload = updateData;
    if (currentProfession == "other") {
      payload["currentProfession"] = currentProfessionOther;
    }

    API.PUT_PROFESSION_DETAIL(userId, payload)
      .then((res) => {
        if (res.status === 200) {
          successToast("Data Updated  ");
          handleNext();
        }
      })
      .catch((err) => {
        errorToast("oops! something went wrong!");
        console.log(
          err?.response?.data?.message ? err.response.data.message : err.message
        );
      })
      .finally(() => setIsLoading(false));
  };
  const UPDATE_WelfareDetials = async (addWelfareDetials) => {
    const { isPakkiSamitiMember, pakkiSamiti, naamDate } = data;
    const userId = localStorage.getItem("userId");
    if (isPakkiSamitiMember && !pakkiSamiti) {
      errorToast("Please Select sewa sammitti Name!");
      return;
    }

    if (
      (!naamDate || naamDate * 1 < 1947 || naamDate * 1 > 2022) &&
      !isCategoryC
    ) {
      errorToast("Please enter valid Naam Date!");
      return;
    } else if (
      naamDate &&
      isCategoryC &&
      (naamDate * 1 < 1947 || naamDate * 1 > 2022)
    ) {
      errorToast("Please enter valid Naam Date!");
      return;
    }

    setIsLoading(true);
    API.PUT_WELFARE_DETAIL(userId, updateData)
      .then((res) => {
        if (res.status === 200) {
          successToast("Data Updated ");
          localStorage.removeItem("userToken");
          handleNext();
        }
      })
      .catch((err) => {
        errorToast("oops! something went wrong!");
        console.log(
          err?.response?.data?.message ? err.response.data.message : err.message
        );
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <div className="flex flex-col justify-between w-full px-3 m-auto my-2 space-y-4 text-black sm:space-y-0 sm:flex-row md:my-4 sm:px-6">
      {isLoading ? (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black opacity-60">
          <Spinner />
        </div>
      ) : null}

      {/* <div className="flex items-center justify-center"> */}
      <button
        onClick={handlePrevious}
        type="submit"
        className="flex p-1 px-4 text-sm text-center text-white transition-colors duration-300 bg-blue-500 rounded-md shadow md:text-lg btns w-max hover:bg-blue-600 focus:outline-none focus:ring-gray-200 focus:ring-4"
      >
        Previous
      </button>
      {/* </div> */}

      {currentTab === 0 && JSON.stringify(updateData) === "{}" && (
        <button
          onClick={() => {
            localStorage.removeItem("parentId");
            localStorage.removeItem("selfDetails");
            localStorage.removeItem("selfFamilyId");
            localStorage.removeItem("userId");
            localStorage.removeItem("userToken");
            localStorage.removeItem("isCategoryC");
            window.location.reload();
          }}
          className="flex p-1 px-4 text-sm text-center text-white transition-colors duration-300 bg-red-500 rounded-md shadow md:text-lg btns w-max hover:bg-red-600 focus:outline-none disabled:cursor-no-drop disabled:bg-gray-400 focus:ring-gray-200 focus:ring-4"
        >
          Add a Fresh Entry
        </button>
      )}
      {/* save & ext / update & Next btn  */}
      {showSaveButton ? (
        <button
          onClick={() => {
            handleSave(false);
          }}
          type="submit"
          className="flex p-1 px-4 text-sm text-center text-white transition-colors duration-300 bg-blue-500 rounded-md shadow md:text-lg btns w-max hover:bg-blue-600 focus:outline-none disabled:cursor-no-drop disabled:bg-gray-400 focus:ring-gray-200 focus:ring-4"
        >
          {currentTab === 3 ? "Save & Create New Family " : "Next"}
        </button>
      ) : (
        <button
          onClick={
            JSON.stringify(updateData) !== "{}" ? handleUpdate : handleNext
          }
          type="submit"
          className="flex p-1 px-4 text-sm text-center text-white transition-colors duration-300 bg-blue-500 rounded-md shadow md:text-lg btns w-max hover:bg-blue-600 focus:outline-none disabled:cursor-no-drop disabled:bg-gray-400 focus:ring-gray-200 focus:ring-4"
        >
          {JSON.stringify(updateData) !== "{}" && "Update & "}
          {currentTab !== 3 ? "Next" : "Exit"}
        </button>
      )}

      {currentTab === 3 &&
        window.location.pathname.split("/")[1] !== "update-entry" && (
          <button
            onClick={() => {
              handleSave(true);
            }}
            type="submit"
            className="flex p-1 px-4 text-sm text-center text-white transition-colors duration-300 bg-green-500 rounded-md shadow md:text-lg btns w-max hover:bg-green-600 focus:outline-none disabled:cursor-no-drop disabled:bg-gray-400 focus:ring-gray-200 focus:ring-4"
          >
            Save & Add Another Family Member
          </button>
        )}
    </div>
  );
};

export default ButtonsLayout;
