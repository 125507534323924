import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import Pagination from '../../components/admin/Pagination';
import Spinner from '../../components/Spinner';
import {institutes} from '../../shared/data';
import {BASEURL, errorToast, successToast, token} from '../../shared/Service';
import {API} from '../../shared/utility';

const RelativeUsers = () => {
	const navigate = useNavigate();
	const [phoneNo, setPhoneNo] = useState('');
	const [onlyApproved, setOnlyApproved] = useState(null);
	const [institute, setInstitute] = useState(null);
	const [users, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [oldResults, setOldResults] = useState([]);
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(10);
	const [totalDocuments, setTotalDocuments] = useState('');
	const [isNewRecord, setIsNewRecord] = useState(true);

	const {id} = useParams();

	const queryString = ({email, name, phoneNo, size, page, onlyApproved}) => {
		var query = '?';

		// if (name) query = `${query}name=${name}&`;
		// if (email) query = `${query}email=${email}&`;
		if (phoneNo) query = `${query}phoneNo=${phoneNo}&`;
		query = `${query}size=${size}&`;
		query = `${query}page=${page}&`;
		query = `${query}onlyApproved=${onlyApproved}&`;
		if (institute) query = `${query}instituteName=${institute}&`;

		return query.substring(0, query.length - 1);
	};

	const handleLoadUsers = async () => {
		setIsLoading(true);

		const query = queryString({
			phoneNo: phoneNo.trim(),
			onlyApproved,
			institute,
			size,
			page,
		});

		API.GET_RELATIVE_USERS({userId: id})
			.then((response) => {
				setUsers(response.data.data);
				// setTotalDocuments(response.data.data.totalDocuments);
				// if (!name && !email && !phoneNo) setOldResults(response.data.data);
				if (!phoneNo) setOldResults(response.data.data);
			})
			.catch((err) => {
				console.log(err);
				errorToast('No user found');
			})
			.finally(() => setIsLoading(false));
	};

	const GET_User_ID = async () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		};
		// console.log(id);

		if (id !== null || id !== undefined) {
			setIsLoading(true);
			axios
				.get(`${BASEURL}/user/${id}`, {headers: headers})
				.then((res) => {
					res?.data?.data?.length === 0 ? setIsNewRecord(true) : setIsNewRecord(false);

					localStorage.setItem('parentId', res.data.data.userId);

					localStorage.setItem(
						'selfDetails',
						JSON.stringify({
							name: res.data.data.name,
							block: res.data.data.block,
							state: res.data.data.state,
							country: res.data.data.country,
							caste: res.data.data.caste,
							district: res.data.data.district,
							address: res.data.data.address,
							village: res.data.data.village,
							vehicle: res.data.data.vehicle,
						})
					);

					localStorage.setItem('selfFamilyId', res.data.data.familyId);
					localStorage.removeItem('userToken');

					navigate(`/add-entry`);
				})
				.catch((err) => {
					console.log(err);
					errorToast('User Does not exist');
				})
				.finally(() => setIsLoading(false));
		}
	};
	const handleResetRecords = () => {
		// setEmail("");
		// setName("");
		setPhoneNo('');
		setUsers(oldResults.users);
	};

	useEffect(() => {
		handleLoadUsers();
	}, [page]);

	useEffect(() => {
		const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	}, []);

	return (
		<div className="min-h-screen py-6 bg-blue-100">
			<div className="py-4 m-auto w-[90%] md:max-w-6xl px-4  bg-white shadow-md shadow-gray-300 rounded-md mt-10">
				<div className="flex flex-col justify-between pl-2 pr-4 space-y-4 sm:space-y-0 sm:flex-row">
					<p className="text-2xl text-gray-700 md:text-3xl">Family Members</p>
					<button className="p-2 px-4 text-sm text-white bg-blue-500 rounded-md shadow-sm shadow-gray-700" onClick={GET_User_ID}>
						Add new member in family
					</button>
				</div>
				<div className="overflow-scroll">
					<table className="min-w-full my-4 bg-blue-100 border divide-y divide-gray-200 shadow-md table-fixed shadow-gray-300 border-gray">
						<thead className="border-b-2 border-white ">
							<tr>
								{heading &&
									heading.map((tr) => {
										return (
											<th scope="col" className="p-3 text-xs font-bold text-left text-gray-500 uppercase md:px-6 md:py-3 whitespace-nowrap">
												{tr}
											</th>
										);
									})}
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">
							{isLoading ? (
								<td colSpan={8}>
									<Spinner css={'my-10'} />
								</td>
							) : (
								users &&
								users?.map((user, index) => {
									return (
										user?.name && (
											<tr key={index} className="cursor-pointer hover:bg-blue-200 ">
												<td className={tdClass}>{size * (page - 1) + index + 1}</td>
												<td className={tdClass}>{user?.name || 'NA'}</td>
												<td className={tdClass}>{user?.callingPhoneNo || 'NA'}</td>
												<td className={tdClass}>{user?.relation}</td>
												<td className={tdClass}>
													<button className="p-2 px-4 text-sm text-white bg-blue-500 rounded-md shadow-sm shadow-gray-700" onClick={() => navigate(`/users/${user.userId}`)}>
														View
													</button>
													<button
														className="p-2 px-4 ml-2 text-sm text-white bg-blue-500 rounded-md shadow-sm shadow-gray-700"
														onClick={() => {
															navigate(`/update-entry/${user.userId}`);
														}}>
														Update
													</button>
												</td>
											</tr>
										)
									);
								})
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default RelativeUsers;

const tdClass = 'md:px-6 md:py-4 p-3 text-left text-sm text-gray-800 whitespace-nowrap capitalize';

const heading = ['S.No', 'Name', 'Phone Number', 'Relation', 'Action'];
