import axios from 'axios';
import {BASEURL, token} from './Service';

const instance = axios.create({
	baseURL: BASEURL,
	timeout: 500000,
	headers: {
		Authorization: `Bearer ${token}`,
		access: localStorage.getItem('userToken'),
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

const responseBody = (response) => response;

const requests = {
	get: (url, body, headers) => instance.get(url, body, headers).then(responseBody),

	post: (url, body, headers) => instance.post(url, body, headers).then(responseBody),

	put: (url, body, headers) => instance.put(url, body, headers).then(responseBody),

	patch: (url, body, headers) => instance.patch(url, body, headers).then(responseBody),

	delete: (url, headers) => instance.delete(url, headers).then(responseBody),
};

const API = {
	//Personal get & post
	GET_PERSONAL_DETAIL(id) {
		return requests.get(`/user/${id}`);
	},

	POST_PERSONAL_DETAIL(body) {
		return requests.post(`/user`, body);
	},
	PUT_PERSONAL_DETAIL(id, body) {
		// console.log(body);
		return requests.put(`/user/${id}`, body);
	},

	//Education get & post
	GET_EDUCATION_DETAIL(id) {
		return requests.get(`/education/${id}`);
	},
	POST_EDUCATION_DETAIL(id, body) {
		return requests.post(`/education/${id}`, body);
	},
	PUT_EDUCATION_DETAIL(id, body) {
		return requests.put(`/education/${id}`, body);
	},

	//profession get & post

	GET_PROFESSION_DETAIL(id) {
		return requests.get(`/profession/${id}`);
	},
	POST_PROFESSION_DETAIL(id, body) {
		return requests.post(`/profession/${id}`, body);
	},
	PUT_PROFESSION_DETAIL(id, body) {
		return requests.put(`/profession/${id}`, body);
	},

	//Welfare get & post
	GET_WELFARE_DETAIL(id) {
		return requests.get(`/welfare/${id}`);
	},
	POST_WELFARE_DETAIL(id, body) {
		return requests.post(`/welfare/${id}`, body);
	},
	PUT_WELFARE_DETAIL(id, body) {
		return requests.put(`/welfare/${id}`, body);
	},

	// admin
	GET_ALL_USERS(query) {
		return requests.get(`/admin/my-users${query}`);
	},

	GET_RELATIVE_USERS(body) {
		return requests.get(`/admin/family/${body.userId}`);
	},
	APPROVE_USER(body) {
		return requests.put(`/admin/approve`, body);
	},
	UNAPPROVE_USER(body) {
		return requests.put(`/admin/unapprove`, body);
	},

	GET_USER_DETAIL(userId) {
		return requests.get(`/admin/user/${userId}`);
	},

	// superadmin
	GET_ALL_ADMIN(query) {
		return requests.get(`/admin/all${query}`);
	},
	GET_ALL_TOTAL_USER(query) {
		return requests.get(`/admin/users/all${query}`);
	},
	GET_STATS(query) {
		return requests.get(`/admin/stats${query}`);
	},
	GET_ALL_USER_LIST(query) {
		return requests.get(`/admin/users/all${query}`);
	},
	GET_STATEWISE_COUNT_LIST(query) {
		return requests.get(`/admin/stats/range${query}`);
	},

	UPDATE_PASSWORD(body) {
		return requests.put(`/admin/update/password`, body);
	},
};

const COMMON = {
	GET_ALL_COUNTRIES() {
		return requests.get(`/countries`);
	},
	GET_ALL_STATES(CountryCode) {
		return requests.get(`/states/${CountryCode}`);
	},
	GET_ALL_CITIES(CountryCode, stateCode) {
		return requests.get(`/cities/${CountryCode}/${stateCode}`);
	},
};

function formateDate(date) {
	let today = new Date(date);
	let dd = String(today.getDate()).padStart(2, '0');
	let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
	let yyyy = today.getFullYear();

	today = dd + '-' + mm + '-' + yyyy;
	return today;
}

// export default Services;
export {requests, API, COMMON, instance, formateDate};
