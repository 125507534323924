import React, { useEffect, useState } from "react";
import { professions, workSectorArray } from "../../shared/data";
import { API } from "../../shared/utility";
import ButtonsLayout from "../ButtonsLayout";
import Spinner from "../Spinner";

const ProfessionalDetails = ({
  currentTab,
  changeTab,
  setShowSuccessModal,
  isUpdating,
}) => {
  const [data, setData] = useState({
    currentProfession: "",
    sector: "",
    occupationName: "",
    designation: "",
  });

  const isCategoryC = JSON.parse(localStorage.getItem("isCategoryC"));
  const [currentProfessionOther, setCurrentProfessionOther] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(true);
  const [updateData, setUpdateData] = useState({});
  const [isCurrentProfessionOther, setIsCurrentProfessionOther] =
    useState(false);
  const [isSectorOther, setIsSectorOther] = useState(false);

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleUpdateChange = (event) => {
    setUpdateData({ ...updateData, [event.target.name]: event.target.value });
  };

  const GetProfessionalDetails = async () => {
    const userId = localStorage.getItem("userId");

    if (userId) {
      setIsLoading(true);
      API.GET_PROFESSION_DETAIL(userId)
        .then((res) => {
          res?.data?.data?.length === 0
            ? setIsNewRecord(true)
            : setIsNewRecord(false);
          const { currentProfession, sector, occupationName, designation } =
            res.data.data;

          const professionalDetails = {
            currentProfession,
            sector,
            occupationName,
            designation,
          };

          if (
            currentProfession !== "government" &&
            currentProfession !== "businessman" &&
            currentProfession !== "housewife" &&
            currentProfession !== "private" &&
            currentProfession !== "student" &&
            currentProfession !== "unemployed" &&
            currentProfession !== "shopkeeper" &&
            currentProfession !== "retired" &&
            currentProfession !== "farmer" &&
            currentProfession
          ) {
            setIsCurrentProfessionOther(true);
          } else {
            setIsCurrentProfessionOther(false);
          }

          const tempSector = workSectorArray
            .sort()
            .filter(
              (workSector) => workSector.toLowerCase() === sector.toLowerCase()
            );

          if (tempSector.length === 0 || sector.toLowerCase() === "other") {
            setIsSectorOther(true);
          } else {
            setIsSectorOther(false);
          }

          setCurrentProfessionOther(currentProfession);
          setData(professionalDetails);
        })
        .catch((err) => {
          // errorToast('Opps! Something went wrong');
          console.log(err);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    GetProfessionalDetails();
  }, []);

  return (
    <div className={`${currentTab === 2 ? "block" : "hidden"}`}>
      {isLoading ? (
        <div className="w-full h-[50vh] flex items-center justify-center bg-white  rounded-lg">
          <Spinner />
        </div>
      ) : (
        <>
          <div className={`w-full flex-col bg-white  rounded-lg flex`}>
            {/* CURRENT POSITION DETAILS */}
            <div className="p-3 sm:p-5 md:flex-1">
              <h1 className="my-3 text-xl text-gray-700 sm:my-4 sm:text-2xl">
                Occupation Details
              </h1>
              <form action="#" className="flex flex-col space-y-5">
                {/*  currentProfession */}
                <div className="flex flex-col space-y-1">
                  <label
                    htmlFor="currentProfession"
                    className={`text-sm ${!isCategoryC && "required"}`}
                  >
                    Current Profession
                  </label>
                  <select
                    className="p-3 text-black bg-transparent border border-gray-300 rounded-md outline-none"
                    name="currentProfession"
                    // value={
                    //   data.currentProfession !== "businessman" &&
                    //   data.currentProfession !== "government" &&
                    //   data.currentProfession !== "private" &&
                    //   data.currentProfession !== "student" &&
                    //   data.currentProfession !== "unemployed"
                    //     ? "other"
                    //     : data.currentProfession
                    // }
                    onChange={(e) => {
                      e.target.value === "other"
                        ? setIsCurrentProfessionOther(true)
                        : setIsCurrentProfessionOther(false);

                      console.log(e.target.value);
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                  >
                    <option value="" defaultValue hidden>
                      -None-
                    </option>

                    <option
                      value="businessman"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "businessman"
                      }
                    >
                      Businessman
                    </option>

                    <option
                      value="farmer"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "farmer"
                      }
                    >
                      Farmer
                    </option>
                    <option
                      value="government"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "government"
                      }
                    >
                      Government Job
                    </option>
                    <option
                      value="housewife"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "housewife"
                      }
                    >
                      House Wife
                    </option>
                    <option
                      value="private"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "private"
                      }
                    >
                      Private Job
                    </option>
                    <option
                      value="retired"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "retired"
                      }
                    >
                      Retired
                    </option>
                    <option
                      value="shopkeeper"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "shopkeeper"
                      }
                    >
                      Shopkeeper
                    </option>
                    <option
                      value="student"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "student"
                      }
                    >
                      Student
                    </option>
                    <option
                      value="unemployed"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "unemployed"
                      }
                    >
                      Unemployed
                    </option>
                    <option value="other" selected={isCurrentProfessionOther}>
                      Other
                    </option>
                  </select>
                </div>

                {/*   Sector */}
                {(data.currentProfession === "private" ||
                  data.currentProfession === "government" ||
                  data.currentProfession === "businessman") && (
                  <div className="flex flex-col space-y-1">
                    <label htmlFor="sector" className="text-sm required">
                      Sector
                    </label>
                    <select
                      className="p-3 text-black bg-transparent border border-gray-300 rounded-md outline-none"
                      name="sector"
                      onChange={(e) => {
                        e.target.value === "other"
                          ? setIsSectorOther(true)
                          : setIsSectorOther(false);

                        if (!isNewRecord) {
                          handleUpdateChange(e);
                        }
                        handleInputChange(e);
                      }}
                    >
                      <option value="" defaultChecked hidden>
                        -none-
                      </option>
                      {workSectorArray.sort().map((workSector, index) => {
                        return (
                          <option
                            value={workSector.toLowerCase()}
                            key={index}
                            selected={
                              data.sector.toLowerCase() ===
                                workSector.toLowerCase() ||
                              (isSectorOther &&
                                workSector.toLowerCase() === "other")
                            }
                          >
                            {workSector}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}

                {/* If Other, Please specify  */}
                {(data.currentProfession === "private" ||
                  data.currentProfession === "government" ||
                  data.currentProfession === "businessman") &&
                  isSectorOther && (
                    <div className={`flex flex-col space-y-1`}>
                      <label htmlFor="sector" className="text-sm required">
                        If Other, Please specify
                      </label>
                      <input
                        type="text"
                        name="sector"
                        value={
                          data.sector === "other"
                            ? setData({ ...data, sector: "" })
                            : data.sector
                        }
                        onChange={(e) => {
                          if (!isNewRecord) {
                            handleUpdateChange(e);
                          }
                          handleInputChange(e);
                        }}
                        required
                        className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                      />
                    </div>
                  )}

                {/* Designation */}
                {(data.currentProfession == "private" ||
                  data.currentProfession == "government") && (
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center justify-between">
                      <label htmlFor="designation" className="text-sm required">
                        Designation
                      </label>
                    </div>
                    <input
                      type="text"
                      name="designation"
                      value={data.designation}
                      onChange={(e) => {
                        if (!isNewRecord) {
                          handleUpdateChange(e);
                        }
                        handleInputChange(e);
                      }}
                      required
                      className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                    />
                  </div>
                )}

                {/* occupationName
                 */}
                {data.currentProfession == "businessman" && (
                  <div className="justify-around md:flex">
                    <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="occupationName
"
                          className="text-sm"
                        >
                          Occupation Name
                        </label>
                      </div>
                      <textarea
                        rows="2"
                        cols="50"
                        type="text"
                        name="occupationName"
                        value={data.occupationName}
                        onChange={(e) => {
                          if (!isNewRecord) {
                            handleUpdateChange(e);
                          }
                          handleInputChange(e);
                        }}
                        required
                        className="w-full px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                      />
                      <p className="hidden mt-2 text-sm peer-invalid:block text-red">
                        please enter valid input
                      </p>
                    </div>
                  </div>
                )}

                {/* other -  Name Of Profession*/}
                {isCurrentProfessionOther && (
                  <div className={`flex flex-col space-y-1`}>
                    <label
                      htmlFor="currentProfession"
                      className="text-sm capitalize required "
                    >
                      Name of Profession
                    </label>
                    <input
                      type="text"
                      name="currentProfession"
                      value={
                        data.currentProfession === "other"
                          ? setData({ ...data, currentProfession: "" })
                          : data.currentProfession
                      }
                      onChange={(e) => {
                        // setCurrentProfessionOther(e.target.value);
                        if (!isNewRecord) {
                          handleUpdateChange(e);
                        }
                        handleInputChange(e);
                      }}
                      className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                    />
                  </div>
                )}
              </form>
            </div>
          </div>
          <div className="py-1 my-5 bg-white rounded-lg">
            <ButtonsLayout
              currentTab={currentTab}
              changeTab={changeTab}
              data={data}
              showSaveButton={isNewRecord}
              updateData={updateData}
              currentProfessionOther={currentProfessionOther}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ProfessionalDetails;
