import { toast } from "react-hot-toast";

export const BASEURL =
  process.env.REACT_APP_MODE === "DEV"
    ? process.env.REACT_APP_DEMO_URL
    : process.env.REACT_APP_PROD_URL;
// export const BASEURL = 'https://demo.allitservices.in/api';

export const COMMON_URL = "https://data.msgallinone.in/common";

export const HUMANITY_BLOCK_API = `https://api.dsshumanityapp.com/api/select_block_new/`;

export const token = localStorage.getItem("Authtoken");

export const successToast = (message) => toast.success(message);
export const errorToast = (message) => toast.error(message);
