import React, { useEffect, useState } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { FaPowerOff, FaUserAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { errorToast } from "../shared/Service";
import { API } from "../shared/utility";

const Navbar = () => {
  const navigate = useNavigate();

  const [isMobileView, setIsMobileView] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [role, setRole] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("resize", function () {
      if (window.innerWidth > 800) {
        setIsMobileView(false);
      } else {
        setIsMobileView(true);
      }
    });

    if (window.innerWidth > 800) setIsMobileView(false);
    else setIsMobileView(true);
    if (localStorage.getItem("Authtoken")) {
      const userDetails = JSON.parse(localStorage.getItem("userInfo"));
      setIsLoggedIn(true);
      setRole(userDetails?.role);
    } else setIsLoggedIn(false);
  }, []);

  const isActiveMenu = (url) => {
    return location.pathname?.toLowerCase() == url.toLowerCase();
  };

  return (
    <div className="py-4 mb-1 bg-blue-100">
      <div className="w-[90%] md:max-w-6xl m-auto nav">
        <div className="flex flex-col justify-between text-black sm:items-center sm:flex-row ">
          <Link to="/" className="flex items-center">
            <div className="flex items-center justify-center w-10 h-10 bg-blue-500 rounded-lg">
              <AiOutlineHome className="text-3xl text-white" />
            </div>
            <p className="ml-4">DSS Data Collection</p>
          </Link>

          <div className="flex flex-col mt-2 sm:mt-0 sm:items-center sm:flex-row space-x-3">
            {role === "admin" && (
              <>
                <div className="my-1 sm:my-0">
                  <ul className="flex sm:items-center space-x-3">
                    <li
                      className={`${left}`}
                      onClick={() => {
                        localStorage.removeItem("parentId");
                        localStorage.removeItem("selfDetails");
                        localStorage.removeItem("selfFamilyId");
                        localStorage.removeItem("userId");
                        localStorage.removeItem("userToken");
                        localStorage.removeItem("isCategoryC");
                        navigate("/add-entry");
                        navigate(0);
                      }}
                    >
                      Add New Family
                    </li>
                    <li
                      className={`${left} `}
                      onClick={() => {
                        localStorage.removeItem("parentId");
                        localStorage.removeItem("selfDetails");
                        localStorage.removeItem("selfFamilyId");
                        localStorage.removeItem("userId");
                        localStorage.removeItem("userToken");
                        localStorage.removeItem("isCategoryC");
                        navigate("/users");
                      }}
                    >
                      View Entry
                    </li>
                  </ul>
                </div>
              </>
            )}

            {role === "superadmin" && (
              <>
                <div className="my-1 sm:my-0">
                  <ul className="flex sm:items-center space-x-3">
                    <li
                      className={`${left}`}
                      onClick={() => {
                        navigate("/admin/all-users");
                      }}
                    >
                      User's List
                    </li>
                    <li
                      className={`${left}`}
                      onClick={() => {
                        navigate("/admin/all-admin");
                      }}
                    >
                      Admin's List
                    </li>
                  </ul>
                </div>
              </>
            )}
            {!isLoggedIn ? (
              <div className="my-1 sm:my-0">
                <ul className="flex items-center space-x-3">
                  <li className={`${left}`}>
                    <Link to="/login">Login</Link>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="my-1 sm:my-0">
                <ul className="flex items-center space-x-3">
                  <li
                    className={`${left}`}
                    onClick={() => navigate("/update-password")}
                  >
                    Update Password
                  </li>
                  <li
                    className={`${left}`}
                    onClick={() => {
                      localStorage.clear();
                      navigate("/login");
                    }}
                  >
                    Logout
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const left =
  "mx-1 bg-blue-500 rounded-md p-1 px-3 text-white text-sm font-normal cursor-pointer";

export default Navbar;
