import {React, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

function PrivateRoutes({Component}) {
	const navigate = useNavigate();

	useEffect(() => {
		let token = localStorage.getItem('Authtoken');

		if (!token) {
			navigate('/login');
		}
	}, []);
	return <Component />;
}

export default PrivateRoutes;
