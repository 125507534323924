import React, {useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import Pagination from '../../components/admin/Pagination';
import Spinner from '../../components/Spinner';
import {institutes} from '../../shared/data';
import {errorToast, successToast} from '../../shared/Service';
import {API} from '../../shared/utility';

const AllAdmin = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [phoneNo, setPhoneNo] = useState('');
	const [name, setName] = useState('');
	const [onlyApproved, setOnlyApproved] = useState(null);
	const [institute, setInstitute] = useState(null);
	const [allAdmins, setAllAdmins] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [oldResults, setOldResults] = useState([]);
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(10);
	const [totalDocuments, setTotalDocuments] = useState('');

	const queryString = ({email, name, phoneNo, size, page, onlyApproved}) => {
		var query = '?';

		// if (name) query = `${query}name=${name}&`;
		// if (email) query = `${query}email=${email}&`;
		if (phoneNo) query = `${query}phoneNo=${phoneNo}&`;
		query = `${query}size=${size}&`;
		query = `${query}page=${page}&`;
		query = `${query}onlyApproved=${onlyApproved}&`;
		if (name) query = `${query}name=${name}&`;

		return query.substring(0, query.length - 1);
	};

	const handleLoadUsers = async () => {
		setIsLoading(true);

		const query = queryString({
			phoneNo: phoneNo.trim(),
			onlyApproved,
			name,
			size,
			page,
		});

		API.GET_ALL_ADMIN(query)
			.then((response) => {
				// console.log(response.data.data.admins);
				setAllAdmins(response.data.data.admins);
				setTotalDocuments(response.data.data.totalDocuments);

				if (!phoneNo) setOldResults(response.data.data);
			})
			.catch((err) => {
				console.log(err);
				errorToast(err.response.data.message);
			})
			.finally(() => setIsLoading(false));
	};

	const handleResetRecords = () => {
		setPhoneNo('');
		setAllAdmins(oldResults.allAdmins);
	};

	const approveUser = async (userId, index) => {
		setIsLoading(true);
		const data = {adminId: userId};
		API.APPROVE_USER(data)
			.then((res) => {
				setAllAdmins((prev) =>
					Object.values({
						...prev,
						[index]: {...prev[index], status: 'active'},
					})
				);
				successToast('User Approved Successfully!');
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);

				errorToast(err.response.data.message);
				// console.log(err ? err.response.data.message : err.message);
			});
	};
	const unapproveUser = async (userId, index) => {
		setIsLoading(true);
		const data = {adminId: userId};
		API.UNAPPROVE_USER(data)
			.then((res) => {
				setAllAdmins((prev) =>
					Object.values({
						...prev,
						[index]: {...prev[index], status: 'inactive'},
					})
				);
				successToast('User unapproved successfully!');
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);

				errorToast(err.response.data.message);
				// console.log(err ? err.response.data.message : err.message);
			});
	};

	useEffect(() => {
		handleLoadUsers();
	}, [page]);

	useEffect(() => {
		const userDetails = JSON.parse(localStorage.getItem('userInfo'));
		if (userDetails?.role !== 'superadmin') {
			navigate('/');
		}
	}, []);

	const heading = ['S.No', 'Name', 'Email', 'Phone No.', , 'Action'];

	const tdClass = 'md:px-6 md:py-4 p-3 text-left text-sm text-gray-800 whitespace-nowrap';

	return (
		<div className="bg-blue-100 py-6 min-h-screen">
			<div className="py-4 m-auto w-[90%] md:max-w-6xl px-4  bg-white shadow-md shadow-gray-300 rounded-md mt-10">
				<p className="text-gray-700 text-2xl md:text-3xl">List of all Admins</p>
				<div className="flex flex-wrap items-end">
					<div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 space-y-1 my-2">
						<label htmlFor="name" className="text-sm  text-gray-400">
							Name
						</label>
						<input
							type="text"
							name="name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							className="px-4 py-2 transition duration-300 border text-gray-400 border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200"
						/>
					</div>
					<div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 space-y-1 my-2">
						<label htmlFor="phoneNo" className="text-sm  text-gray-400">
							Phone Number
						</label>
						<input
							type="number"
							name="phoneNo"
							value={phoneNo}
							onChange={(e) => setPhoneNo(e.target.value)}
							className="px-4 py-2 transition duration-300 border text-gray-400 border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200"
						/>
					</div>

					<div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 space-y-1 my-2">
						<label htmlFor="onlyApproved" className="text-sm  text-gray-400">
							Status
						</label>
						<select
							className="text-black px-3 sm:px-4 py-2 border-gray-300 border rounded-md bg-transparent"
							name="onlyApproved"
							value={onlyApproved}
							onChange={(e) => {
								setOnlyApproved(e.target.value);
							}}>
							<option value={null}>All Admins</option>
							<option value={true}>Approved Admins</option>
							<option value={false}>Unapprove Admins</option>
						</select>
					</div>
					<div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 my-2">
						<button
							className="bg-blue-500 rounded-md shadow-sm
            text-white 
            shadow-gray-700 p-2 px-4"
							onClick={handleLoadUsers}>
							Search
						</button>
					</div>
				</div>
				<div className="overflow-auto">
					<table
						className="my-4 shadow-md bg-blue-100
         shadow-gray-300 table-fixed 
         min-w-full divide-y divide-gray-200">
						<thead className="">
							<tr>
								{heading &&
									heading.map((tr) => {
										return (
											<th scope="col" className="p-3 md:px-6 md:py-3 text-xs font-bold text-left text-gray-500 uppercase whitespace-nowrap">
												{tr}
											</th>
										);
									})}
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">
							{isLoading ? (
								<td colSpan={8}>
									<Spinner css={'my-10'} />
								</td>
							) : (
								allAdmins.length > 0 &&
								allAdmins.map((admin, index) => {
									return (
										admin && (
											<tr key={index}>
												<td className={tdClass}>{size * (page - 1) + index + 1}</td>
												<td className={tdClass}>{admin?.name || 'NA'}</td>
												<td className={tdClass}>{admin?.email || 'NA'}</td>
												<td className={tdClass}>{admin?.phoneNo || 'NA'}</td>

												<td className={tdClass}>
													<button
														className="bg-blue-500 rounded-md shadow-sm text-white shadow-gray-700 p-2 px-4 text-sm"
														onClick={() => {
															if (admin.status === 'active') {
																unapproveUser(admin.adminId, index);
															} else {
																approveUser(admin.adminId, index);
															}
														}}>
														{admin.status === 'active' ? 'Unapprove' : 'Approve'}
													</button>
												</td>
											</tr>
										)
									);
								})
							)}
						</tbody>
					</table>
				</div>
				{!isLoading ? <Pagination totalDocuments={totalDocuments} size={size} setPage={setPage} page={page} /> : null}
			</div>
		</div>
	);
};

export default AllAdmin;
