import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PasswordImg from "../assets/images/password.svg";
import PrimaryButton from "../components/PrimaryButton";
import Spinner from "../components/Spinner";
import {
  BASEURL,
  COMMON_URL,
  errorToast,
  successToast,
} from "../shared/Service";
import { API } from "../shared/utility";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [authToken, setAuthToken] = useState();
  const [data, setData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const updatePassword = async (event) => {
    event.preventDefault();

    if (
      data.confirmPassword === data.newPassword &&
      data.newPassword.length >= 8
    ) {
      setIsLoading(true);
      const body = { password: data.newPassword };

      API.UPDATE_PASSWORD(body)
        .then((res) => {
          if (res) {
            successToast(res?.data?.message);
            localStorage.clear()
            navigate("/");
          }
        })
        .catch((e) => {
          errorToast(e.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (data.newPassword.length < 8) {
      errorToast("Password Length must be greater than 8.");
    } else {
      errorToast("Password must be same");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-100 ">
      <div className="w-[90%] md:w-[900px] shadow-sm">
        {isLoading ? (
          <div className="w-full h-[50vh] flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <p className="mb-5 text-2xl font-bold text-gray-600 md:text-3xl">
              Update Password
            </p>
            <div className="items-center p-4 m-auto bg-white shadow-gray-300 md:p-8 md:flex">
              <div className="w-[90%] md:m-auto mb-4 mx-auto order-1 md:w-2/4">
                <img
                  src={PasswordImg}
                  className="w-[60%] m-auto"
                  alt="password"
                />
              </div>
              <div className="w-[90%] m-auto order-2 md:w-2/4">
                {/*  password */}
                <div className="flex flex-col my-2 space-y-1">
                  <label
                    htmlFor="newPassword"
                    className="text-sm text-gray-400"
                  >
                    New Password
                  </label>

                  <input
                    type={showPassword ? "text" : "password"}
                    name="newPassword"
                    onChange={handleInputChange}
                    value={data.newPassword}
                    className="px-4 py-2 text-gray-500 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-200"
                  />
                </div>

                {/* update password */}
                <div className="flex flex-col my-2 space-y-1">
                  <label
                    htmlFor="confirmPassword"
                    className="text-sm text-gray-400"
                  >
                    Confirm Password
                  </label>

                  <input
                    type={showPassword ? "text" : "password"}
                    name="confirmPassword"
                    onChange={handleInputChange}
                    value={data.confirmPassword}
                    className="px-4 py-2 text-gray-500 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-200"
                  />
                </div>

                {/* show pass checkbox */}
                <div className="flex w-full mt-4 space-x-2">
                  <input
                    type="checkbox"
                    id="remember"
                    className="w-4 h-4 transition duration-300 rounded accent-gray-500 focus:ring-2 focus:ring-offset-0 focus:outline-none focus:ring-gray-200"
                    onChange={(e) => {
                      e.target.checked
                        ? setShowPassword(true)
                        : setShowPassword(false);
                    }}
                  />
                  <div className="flex items-center justify-between w-full">
                    <label htmlFor="remember" className="text-sm text-gray-500">
                      Show Password
                    </label>
                  </div>
                </div>

                {/* {isLoading && <FullScreenSpinner />} */}
                <PrimaryButton
                  disable={false}
                  action={updatePassword}
                  text="Update Password"
                  bg="bg-blue-500"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UpdatePassword;
