import React, {useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import Pagination from '../../components/admin/Pagination';
import Spinner from '../../components/Spinner';
import {institutes} from '../../shared/data';
import {errorToast, successToast} from '../../shared/Service';
import {API} from '../../shared/utility';

const AllUsers = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [phoneNo, setPhoneNo] = useState('');
	const [name, setName] = useState('');
	const [onlyApproved, setOnlyApproved] = useState(null);
	const [institute, setInstitute] = useState(null);
	const [users, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [oldResults, setOldResults] = useState([]);
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(5);
	const [totalDocuments, setTotalDocuments] = useState('');

	const queryString = ({email, name, phoneNo, size, page, onlyApproved}) => {
		var query = '?';

		// if (name) query = `${query}name=${name}&`;
		// if (email) query = `${query}email=${email}&`;
		if (phoneNo) query = `${query}phoneNo=${phoneNo}&`;
		query = `${query}size=${size}&`;
		query = `${query}page=${page}&`;
		query = `${query}onlyApproved=${onlyApproved}&`;
		if (institute) query = `${query}instituteName=${institute}&`;

		return query.substring(0, query.length - 1);
	};

	const handleLoadUsers = async () => {
		setIsLoading(true);

		const query = queryString({
			phoneNo: phoneNo.trim(),
			onlyApproved,
			institute,
			size,
			page,
		});

		API.GET_ALL_USERS(query)
			.then((response) => {
				setUsers(response.data.data.users);
				setTotalDocuments(response.data.data.totalDocuments);
				// if (!name && !email && !phoneNo) setOldResults(response.data.data);
				if (!phoneNo) setOldResults(response.data.data);
			})
			.catch((err) => {
				console.log(err);
				errorToast(err.response.data.message);
			})
			.finally(() => setIsLoading(false));
	};

	const handleResetRecords = () => {
		// setEmail("");
		// setName("");
		setPhoneNo('');
		setUsers(oldResults.users);
	};

	useEffect(() => {
		handleLoadUsers();
	}, [page]);

	useEffect(() => {
		const userInfo = JSON.parse(localStorage.getItem('userInfo'));
		if (userInfo?.role !== 'admin') {
			navigate('/');
		}
	}, []);

	const heading = ['S.No', 'Name', 'Phone Number', 'Family Head info', 'Family Info'];

	const tdClass = 'md:px-6 md:py-4 p-3 text-left text-sm text-gray-800 whitespace-nowrap cursor-pointer capitalize';

	return (
		<div className="bg-blue-100 py-6 min-h-screen">
			<div className="py-4 m-auto w-[90%] md:max-w-6xl px-4  bg-white shadow-md shadow-gray-300 rounded-md mt-10">
				<p className="text-gray-700 text-2xl md:text-3xl">Family Head</p>

				<div className="overflow-scroll">
					<table
						className="my-4 shadow-md bg-blue-100
         shadow-gray-300 table-fixed 
         min-w-full divide-y divide-gray-200 border border-gray">
						<thead className=" border-b-2 border-white">
							<tr>
								{heading &&
									heading.map((tr) => {
										return (
											<th scope="col" className="p-3 md:px-6 md:py-3 text-xs font-bold text-left text-gray-500 uppercase whitespace-nowrap">
												{tr}
											</th>
										);
									})}
							</tr>
						</thead>
						<tbody className="divide-y divide-white">
							{isLoading ? (
								<td colSpan={8}>
									<Spinner css={'my-10'} />
								</td>
							) : (
								users &&
								users.map((user, index) => {
									return (
										user?.name && (
											<tr key={index} className="hover:bg-blue-200 ">
												<td className={tdClass} onClick={() => navigate(`/family/${user.userId}`)}>
													{size * (page - 1) + index + 1}
												</td>
												<td className={`${tdClass} hover:text-blue-600  hover:underline`} onClick={() => navigate(`/family/${user.userId}`)}>
													{user?.name || 'NA'}
												</td>
												<td className={tdClass} onClick={() => navigate(`/family/${user.userId}`)}>
													{user?.callingPhoneNo || 'NA'}
												</td>
												{/* <td className={tdClass} onClick={() => navigate('/family')}>{user?.whatsappPhoneNo || 'NA'}</td> */}
												<td className={tdClass}>
													<button className="bg-blue-500 rounded-md shadow-sm text-white shadow-gray-700 p-2 px-4 text-sm" onClick={() => navigate(`/users/${user.userId}`)}>
														View
													</button>
													<button
														className="ml-2 bg-blue-500 rounded-md shadow-sm text-white shadow-gray-700 p-2 px-4 text-sm"
														onClick={() => {
															// console.log(user);
															// localStorage.setItem('userToken', user);
															navigate(`/update-entry/${user.userId}`);
														}}>
														Update
													</button>
												</td>
												<td className={tdClass}>
													<button className="bg-blue-500 rounded-md shadow-sm text-white shadow-gray-700 p-2 px-4 text-sm" onClick={() => navigate(`/family/${user.userId}`)}>
														View Family
													</button>
												</td>
											</tr>
										)
									);
								})
							)}
						</tbody>
					</table>
				</div>
				{/* {!isLoading ? <Pagination totalDocuments={totalDocuments} size={size} setPage={setPage} page={page} /> : null} */}
			</div>
		</div>
	);
};

export default AllUsers;
