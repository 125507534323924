export const professions = ['Businessman', 'Government Job', 'House Wife', 'Private Job', 'Retired', 'Shopkeeper', 'Student', 'Unemployed'];

export const qualificationArray = ['Class Below 5', '5th', '8th', '10th', '12th', 'CA', 'Graduation', 'M.Phil', 'Post Graduation', 'Phd', 'Uneducated'];

export const RelationData = [
	{
		label: 'Brother',
		value: 'brother',
	},
	{
		label: 'Brother In Law',
		value: 'brotherinlaw',
	},
	{
		label: 'Daughter',
		value: 'daughter',
	},
	{
		label: 'Daughter In Law',
		value: 'daughterinlaw',
	},
	{
		label: 'Family Member',
		value: 'familymember',
	},

	{
		label: 'Father',
		value: 'father',
	},
	{
                lable: 'Grand Father',
                value: 'grandfather',
        },
        {
                lable: 'Grand Mother',
                value: 'grandmother',
        },

	{
		label: 'Grand Daughter In Law',
		value: 'granddaughterinlaw',
	},
	{
		label: 'Grand Son In Law',
		value: 'grandsoninlaw',
	},
	{
		label: 'Grand Daughter',
		value: 'granddaughter',
	},
	{
		label: 'Grand Son',
		value: 'grandson',
	},

	{
		label: 'Great Grand Daughter',
		value: 'greatgranddaughter',
	},
	{
		label: 'Great Grand Son',
		value: 'greatgrandson',
	},
	{
		label: 'Husband',
		value: 'husband',
	},
	{
		label: 'Mother',
		value: 'mother',
	},
	{
		label: 'Nephew',
		value: 'nephew',
	},
	{
		label: 'Niece',
		value: 'niece',
	},
	{
		label: 'Paternal Uncle',
		value: 'paternaluncle',
	},
	{
		label: 'Paternal Uncle',
		value: 'paternaluncle',
	},
	{
		label: 'Paternal Aunt',
		value: 'paternalaunt',
	},
	{
		label: 'Sister',
		value: 'sister',
	},
	{
		label: 'Sister In Law',
		value: 'Sisterinlaw',
	},
	{
		label: 'Son',
		value: 'son',
	},
	{
		label: 'Son In Law',
		value: 'soninlaw',
	},
	{
		label: 'Wife',
		value: 'wife',
	},
];
export const sammittiArray = [
	{
		value: 248,
		label: '7 MEM',
	},
	{
		value: 249,
		label: '15 MEM',
	},
	{
		value: 999,
		label: '25 MEM',
	},
	{
		value: 247,
		label: '45 MEM',
	},

	{
		value: 55,
		label: 'Aasra Ashram Samiti	',
	},
	{
		value: 142,
		label: 'Aata Samiti',
	},
	{
		value: 54,
		label: 'Action Comitee',
	},
	{
		value: 40,
		label: 'Address Samiti',
	},
	{
		value: 113,
		label: 'Adm block',
	},
	{
		value: 20,
		label: 'Aara Samiti',
	},

	{
		value: 174,
		label: 'Advocate Samiti',
	},

	{
		value: 244,
		label: 'AJUBA MACHINE',
	},
	{
		value: 169,
		label: 'Alovera Samiti',
	},
	{
		value: 44,
		label: 'Ang Peir Samiti	',
	},
	{
		value: 89,
		label: 'Animal Samiti',
	},
	{
		value: 62,
		label: 'Anouncement Samiti',
	},
	{
		value: 141,
		label: 'Ayurvad Samiti',
	},
	{
		value: 70,
		label: 'Bag Samiti',
	},
	{
		value: 48,
		label: 'Baghvani Samiti',
	},

	{
		value: 45,
		label: 'Bagwani',
	},
	{
		value: 177,
		label: 'Banni Samiti',
	},
	{
		value: 79,
		label: 'Bartan Samiti',
	},
	{
		value: 117,
		label: 'Beldar Samiti',
	},
	{
		value: 210,
		label: 'Bhangidas',
	},
	{
		value: 11,
		label: 'Bijali Samiti',
	},
	{
		value: 211,
		label: 'Block Bhangidas',
	},
	{
		value: 143,
		label: 'Blood Donation & Eye Donation Samiti',
	},
	{
		value: 178,
		label: 'Bujurag Samiti',
	},
	{
		value: 46,
		label: 'Bus Samiti',
	},
	{
		value: 224,
		label: 'Cabin Samiti',
	},
	{
		value: 26,
		label: 'Canteen Stall Samiti',
	},
	{
		value: 116,
		label: 'Cassette Samiti',
	},
	{
		value: 134,
		label: 'Centre Store Samiti',
	},
	{
		value: 219,
		label: 'Chakki Samiti',
	},
	{
		value: 238,
		label: 'Checking Mechine Samiti',
	},
	{
		value: 5,
		label: 'Chhayawan Samiti',
	},
	{
		value: 97,
		label: 'Clock Samiti',
	},
	{
		value: 28,
		label: 'Cobbler Samiti',
	},
	{
		value: 110,
		label: 'Conducter Samiti',
	},
	{
		value: 159,
		label: 'Control Samiti',
	},
	{
		value: 47,
		label: 'Cycle Stand Samiti',
	},
	{
		value: 96,
		label: 'Daana Samiti',
	},
	{
		value: 99,
		label: 'Dabwali Canteen',
	},
	{
		value: 52,
		label: 'Dairy Samiti',
	},
	{
		value: 29,
		label: 'Decoration Samiti',
	},
	{
		value: 119,
		label: 'Dhobi Ghat',
	},
	{
		value: 34,
		label: 'Doctor Samiti',
	},
	{
		value: 92,
		label: 'Dress Numbering Samiti',
	},
	{
		value: 109,
		label: 'Driver Samiti',
	},

	{
		value: 27,
		label: 'Dudh (Milk) Sambhal Samiti',
	},
	{
		value: 83,
		label: 'Engineer Samiti',
	},
	{
		value: 17,
		label: 'Enquiry Samiti',
	},
	{
		value: 148,
		label: 'Factory Samiti',
	},
	{value: 172, label: 'Fast Food Samiti'},
	{value: 105, label: 'Fatehabad Canteen'},
	{value: 165, label: ' Father Chips Samiti'},
	{value: 118, label: ' Flour Mill'},
	{value: 30, label: ' Fruit Samiti'},
	{value: 164, label: ' Furniture House Samiti'},
	{value: 229, label: ' Gate no. 3 samiti'},
	{value: 230, label: ' Gate no. 8 samiti'},
	{value: 36, label: ' Gate Samiti'},
	{value: 49, label: ' General Store Samiti'},
	{value: 71, label: ' Generator Samiti'},
	{value: 234, label: ' Green S Pandal Samiti'},
	{value: 93, label: ' Green S Wing Samiti'},
	{value: 207, label: ' Greens Jimmewar'},
	{value: 22, label: ' Guest Samiti'},
	{value: 128, label: ' Hair Dress Samiti'},
	{value: 58, label: ' 	Halwai Samiti'},
	{value: 147, label: ' Health Care Samiti'},
	{value: 35, label: ' 	Hospital Samiti'},
	{value: 139, label: ' Hostel Samiti'},
	{value: 145, label: ' 	Ice Samiti'},
	{value: 121, label: ' Iron Samiti'},
	{value: 204, label: ' 	Jaam-E-Insan Registration Samiti'},
	{value: 188, label: ' Janch-Padtal Samiti'},
	{value: 80, label: ' Janglaat Samiti'},
	{value: 73, label: ' 	Jari Buti Samiti'},
	{value: 133, label: ' Joda Samiti'},
	{value: 63, label: ' Kabaad Samiti'},
	{value: 222, label: ' 	Kadak Tadak Samiti'},
	{value: 203, label: ' Kafila Samiti'},
	{value: 189, label: ' kalyan samiti'},
	{value: 84, label: ' Kanak Samiti'},
	{value: 195, label: ' 	Kanya Bhrun Hatya Roko Samiti'},
	{value: 107, label: ' Kashish Samiti'},
	{value: 50, label: ' Kaviraj Samiti'},
	{value: 226, label: ' 	Khadya Anaj Samiti'},
	{value: 60, label: ' 	Kheti-Bari Samiti'},
	{value: 8, label: ' Khuli Canteen Samiti'},
	{value: 24, label: ' 	Khuli Sewa'},
	{value: 161, label: ' 	Labour Department'},
	{value: 129, label: ' 	Lakkar Samiti'},
	{value: 138, label: ' 	Lamination Samiti'},
	{value: 2, label: ' 	Langar Banane wali Samiti'},
	{value: 198, label: ' 	Langar khilane wale (Bhai) Samiti'},
	{value: 199, label: ' 	Langar khilane wali Samiti'},
	{value: 232, label: ' 	Langar Pakane Wali Samiti'},
	{value: 243, label: ' 	LANGAR SAMITI'},
	{value: 108, label: ' 	Maali Samiti'},
	{value: 231, label: ' 	Main Gate Samiti'},
	{value: 149, label: ' 	Market Samiti'},
	{value: 155, label: ' 	Masala Samiti'},
	{value: 124, label: ' Mechanic Samiti'},
	{value: 157, label: ' 	Medical'},
	{value: 158, label: ' 	Medical Room'},
	{value: 39, label: ' Mistri Samiti'},
	{value: 217, label: ' 	Mobile Samiti'},
	{value: 162, label: ' 	Mochi Samiti'},
	{value: 245, label: ' 	MOTOR NO 17'},
	{value: 77, label: ' 	Motor No. 04'},
	{value: 103, label: ' 	Motor No. 05'},
	{value: 131, label: ' 	Motor No. 07'},
	{value: 111, label: ' 	Motor No. 08'},
	{value: 59, label: ' 	Motor No. 10'},
	{value: 65, label: ' 	Motor No. 15'},
	{value: 101, label: ' 	Motor No. 16'},
	{value: 87, label: ' 	Motor No. 20'},
	{value: 90, label: ' Motor No. 22'},
	{value: 104, label: ' Motor No. 23'},
	{value: 112, label: ' 	Motor Samiti'},
	{
		value: 18,
		label: 'MSG IT Wing',
	},
	{value: 206, label: ' 	Naam Jaam Samiti'},
	{value: 202, label: ' 	Nasha Chudao Bros Samiti'},
	{value: 205, label: ' 	Nasha Chudao Samiti'},
	{value: 201, label: ' 	Nasha Chudao Sisters Samiti'},
	{value: 23, label: ' 	Naujawan Samiti'},
	{value: 69, label: ' 	Newspaper Hawker Samiti'},
	{value: 31, label: ' Nigrani Samiti'},
	{value: 144, label: ' 	Nursary Samiti'},
	{value: 176, label: ' Nurse Samiti'},
	{value: 221, label: ' 	Organic Stall Samiti'},
	{value: 74, label: ' P.C.O Samiti'},
	{value: 215, label: ' Pahra Samiti Left'},
	{value: 7, label: ' 	Pahra Samiti Right'},
	{value: 67, label: ' Painter Samiti'},
	{value: 246, label: ' 	PAKKI SEWA'},
	{value: 6, label: ' Pandal Samiti'},
	{value: 3, label: ' 	Pani Samiti'},
	{value: 94, label: ' Para Medical Staff Samiti'},
	{value: 223, label: ' Parattha Samiti'},
	{value: 182, label: ' 	Parshad Samiti'},
	{value: 167, label: ' 	Pasa Commitee'},
	{value: 114, label: ' 	Pashoo Samiti'},
	{value: 218, label: ' 	Pevalue Samiti'},
	{value: 115, label: ' 	Pesh Samiti'},
	{value: 152, label: ' 	Phatta Samiti'},
	{value: 135, label: ' 	Phitar Samiti'},
	{value: 15, label: ' 	Photo Lamination Samiti'},
	{value: 9, label: ' Photo Granth Samiti'},
	{value: 106, label: ' 	Plaza Samiti'},
	{value: 88, label: ' 	Plumber Samiti'},
	{value: 193, label: ' 	Podha-Ropan Samiti'},
	{value: 78, label: ' 	Police Samiti'},
	{value: 82, label: ' 	Political Wing'},
	{value: 33, label: ' 	Press Samiti'},
	{value: 125, label: ' 	Pump Samiti'},
	{value: 91, label: ' 	Rajai Samiti'},
	{value: 200, label: ' 	Rakt-Daan va Netr-Daan Samiti'},
	{value: 160, label: ' 	Repair Samiti'},
	{value: 19, label: ' 	Road Samiti'},
	{value: 16, label: ' 	Rojgar Vibhag'},
	{value: 154, label: ' 	Room No. 07'},
	{value: 153, label: ' 	Room No. 10'},
	{value: 64, label: ' 	Room No. 50'},
	{value: 175, label: ' 	Room No. 68'},
	{value: 137, label: ' 	S.S.G.Techinic'},
	{value: 85, label: ' 	Sabji Fruit Canteen Samiti'},
	{value: 130, label: ' 	Sach Foods'},
	{value: 179, label: ' 	Sach Kahoon & Sachi Shiksha Prachar Samiti'},
	{value: 194, label: ' 	Sach Kahoon va Sachi Shiksha Prachar Samiti'},
	{value: 42, label: ' 	Sach Motor Garage Samiti'},
	{value: 140, label: ' 	Sach Petroleum Samiti'},
	{value: 216, label: ' 	Sach Petroleum Samiti'},
	{value: 57, label: ' 	Sachkhand Hall Samiti'},
	{value: 56, label: ' 	Sachkhand Samiti'},
	{value: 181, label: ' 	Sadak Samiti'},
	{value: 250, label: ' 	SAFAI ABHIYAN'},
	{value: 66, label: ' 	Safai Samiti'},
	{value: 197, label: ' 	Safai Samiti Bros'},
	{value: 196, label: ' 	Safai Samiti Sisters'},
	{value: 100, label: ' 	Safeda Samiti'},
	{value: 4, label: ' 	Saman Sambhal Samiti'},
	{value: 240, label: ' 	Saman Samiti'},
	{value: 168, label: ' 	Sambhal Samiti'},
	{value: 41, label: ' School Samiti'},
	{value: 122, label: ' 	Seat Maker'},
	{value: 123, label: ' 	Seat Maker Samiti'},
	{value: 163, label: ' 	Security Samiti'},
	{value: 43, label: ' 	Seed Samiti'},
	{value: 13, label: ' 	Sewa Samiti'},
	{value: 166, label: ' 	Shahbad Canteen Samiti'},
	{value: 120, label: ' 	Shahi Bhajan Mandli Samiti'},
	{value: 236, label: ' 	Shahi Canteen Sabji Samiti'},
	{value: 1, label: ' 	Shahi Canteen Samiti'},
	{value: 95, label: ' 	Shifting Samiti'},
	{value: 81, label: ' Silai Samiti'},
	{value: 239, label: ' 	SMJD Pahra Samiti'},
	{value: 235, label: ' 	SMJD Sabji Fruit Canteen Samiti'},
	{value: 242, label: ' 	SMJD Shahi Canteen Samiti'},
	{value: 227, label: ' 	Snan Ghar Pehra Samiti'},
	{value: 136, label: 'Sofa Samiti'},
	{value: 12, label: 'Speaker Samiti'},
	{value: 171, label: 'Sports Samiti'},
	{value: 102, label: 'Sri Gurusarmodia Hostel'},
	{value: 233, label: 'SSJD Sabji Fruit Canteen Samiti'},
	{value: 237, label: 'SSJD Sadak Samiti'},
	{value: 241, label: 'SSJD Shahi Canteen Samiti'},
	{value: 75, label: 'Stadium Samiti'},
	{value: 10, label: 'Stage Samiti'},
	{value: 127, label: 'Star samiti'},
	{value: 32, label: 'Sugam Construction Co.'},
	{value: 214, label: 'SUJAN BAHAN'},
	{value: 151, label: 'Sunder Mahal Samiti'},
	{value: 225, label: 'Suparbhat Samiti'},
	{value: 156, label: 'Super Market'},
	{value: 51, label: 'T.V Samiti'},
	{value: 38, label: 'Tailor Samiti'},
	{value: 170, label: 'Tank Samiti'},
	{value: 173, label: 'Taps Samiti'},
	{value: 53, label: 'Telephone Samiti'},
	{value: 76, label: 'Tikki Samiti'},
	{value: 86, label: 'Tile Marbel Samiti'},
	{value: 150, label: 'Tokara Samiti'},
	{value: 21, label: 'Token Samiti'},
	{value: 37, label: 'Tools Samiti'},
	{value: 220, label: 'Tora-Fera Samiti'},
	{value: 228, label: 'Traffic Pahra Samiti'},
	{value: 68, label: 'Traffic Samiti'},
	{value: 180, label: 'Training'},
	{value: 14, label: 'Transport Samiti'},
	{value: 126, label: 'Tree Plantation Samiti'},
	{value: 25, label: 'True World Samiti'},
	{value: 98, label: 'Tutian Samiti'},
	{value: 146, label: 'Vvalueeo Samiti'},
	{value: 132, label: 'Water Plant Samiti'},
	{value: 72, label: 'Welding Samiti'},
	{value: 61, label: 'Work Shop'},
	{value: 208, label: 'Youth Virangnayein Samiti'},
	{value: 209, label: 'Youth Welfare Federation'},
	{value: '', label: 'Other'},
];

export const bloodGroupData = [
	{
		label: 'A+',
		value: 'A+',
	},
	{
		label: 'A-',
		value: 'A-',
	},
	{
		label: 'B+',
		value: 'B+',
	},
	{
		label: 'B-',
		value: 'B-',
	},
	{
		label: 'O+',
		value: 'O+',
	},
	{
		label: 'O-',
		value: 'O-',
	},
	{
		label: 'AB+',
		value: 'AB+',
	},
	{
		label: 'AB-',
		value: 'AB-',
	},
];

export const instructionArr = [
	{
		name: 'INSTRUCTIONS FOR FORMS ENTRY SEVA ',
		description: `सबसे पहले लैपटॉप या मोबाइल में एंट्री शुरू करने से पहले आपने फॉर्म को पूरा ध्यान से देखना है जी यदि उसमें नीचे बताए अनुसार कोई कमी है तो आपने उसे reject कर देना है जी। 
आपने फॉर्म के ऊपर कुछ भी cutting इत्यादि नहीं करनी, सिर्फ उन्हें अलग रख देना है और जिम्मेवार को बताना है कि यह rejected फॉर्म हैं। `,
		subDescription: [],
	},
	{
		name: `निम्न condition में आपने फॉर्म को reject करना है जी। `,
		description: ``,
		subDescription: [
			` यदि परिवार की डिटेल में एक भी मोबाइल नंबर नहीं लिखा हुआ। ( कम से कम एक मोबाइल नंबर भरा होना जरूरी है, बाकि मेम्बर्स के मोबाइल की जगह भी आप वो नंबर ही भर सकते हो। )`,

			`जन्म तिथि या आयु हर मेम्बर की लिखी होनी जरूरी है, यदि 5 में से 4 मेम्बर्स की आयु लिखी है लेकिन 1 की नहीं तो भी आपको वो फॉर्म एंटर नहीं करना जी। `,

			`नाम कब लिया इस कॉलम में भी सभी मेम्बर्स के नाम लेने का वर्ष या कितने वर्ष हो गए लिखा होना जरूरी है, एक भी मेम्बर की यह डिटेल नहीं होने पर आपने पूरा फॉर्म ही एंटर नहीं करना जी। `,

			`व्यवसाय के कॉलम में कम से कम एक मेम्बर का व्यवसाय लिखा होना जरूरी है जी। यदि नहीं लिखा हुआ तो फॉर्म एंटर नहीं करना जी। `,

			`एक फॉर्म में एक ही परिवार की एंट्री होनी चाहिए, ताकि उसका मुखिया के साथ रिलेशन बताया जा सके`,
		],
	},
	{
		name: `मुखिया कौन होगा ?`,
		description: `कुछ फॉर्म्स में आपको मुखिया के नाम के स्थान पर घर के किसी बड़े सदस्य का नाम लिखा हुआ मिलेगा लेकिन उन्होंने नामदान नहीं ले रखा,
तो बाकि सदस्य जिन्होंने नाम ले रखा है उनमें से सबसे बड़े सदस्य को घर का मुखिया लिखना है जी`,
		subDescription: [],
	},
	{
		name: `Relation के कॉलम में ध्यान रखने योग्य बात:`,
		description: ``,
		subDescription: [
			`जब आप पहले मेम्बर या मुखिया की एंट्री कर रहे हो तो by default, relation में self ही लिखा आएगा। `,
			`लेकिन जब आप मुखिया के अलावा दूसरे किसी मेम्बर की एंट्री करोगे तो आपने रिलेशन वाले कॉलम में मुखिया से क्या रिश्ता है वो भरना है।`,
			`यह ध्यान रखने योग्य बात है कि बाकि सभी मेम्बर्स का रिलेशन मुखिया के साथ क्या है वो ही आपने भरना है, चाहे relative name में कोई दूसरा नाम हो।`,
		],
	},
	{
		name: 'अन्य ध्यान देने योग्य बात: ',
		description: `Date of birth वाले कॉलम में आप चेक कर लें कि फॉर्मेट dd/mm/yyyy है या mm/dd/yyyy है, तो जिस तरह का फॉर्मेट आपके लैपटॉप या मोबाइल में आ रहा है आपने उस अनुसार ही भरना है जी।`,
		subDescription: [],
	},
];

export const workSectorArray = [
	'Manufacturing',
	'Sports',
	'Technology',
	'Health Care',
	'Finance',
	'Stock Market',
	'Economics',
	'Computer and IT',
	'Energy',
	'Telecommunications',
	'Agriculture',
	'Retail',
	'Communication',
	'Hospitality',
	'Financial Services',
	'Real Estate',
	'Transport',
	'Mining',
	'Food',
	'E-commerce',
	'Education Department',
	'Teacher',
	'Medical Devices',
	'Aviation',
	'Engeneering',
	'Pharmaceutical',
	'Research',
	'Health',
	'Logistics',
	'Insurance',
	'Defence/Police',
	'Judiciary',
	'Other',
];
// export const boysInstitutes = ['Shah Satnam Ji Boys School', 'Shah Satnam Ji Boys College', 'Shah Satnam Ji College of Education', 'Shah Satnam Ji Institute of Technology and Mgt'];

// export const girlsInstitutes = ['Shah Satnam Ji Girls School', 'Shah Satnam Ji Girls College', 'Shah Satnam Ji College of Education', 'Shah Satnam Ji Institute of Technology and Mgt'];

export const institutes = [
	'Shah Satnam Ji Boys School, Sirsa',
	'Shah Satnam Ji Boys School, Sri Gurusar Modia',
	'Shah Satnam Ji Girls School, Sirsa',
	'Shah Satnam Ji Girls School, Sri Gurusar Modia',
	'Shah Satnam Ji Girls School, Budni',
	'Shah Satnam Ji Girls School, Taranagar',
	'Shah Satnam Ji Girls Noble School, Kotda',
	'Shah Satnam Ji Boys College, Sirsa',
	'Shah Satnam Ji Girls College, Sirsa',
	'Shah Satnam Ji Girls College, Sri Gurusar Modia',
	'Saint Msg Glorious International School, Sirsa',
	'Shah Satnam Ji College of Education, Sirsa',
	'Shah Satnam Ji Institute of Technology and Management, Sirsa',
];

// export const degreeOrDiplomaName = ['Uneducated', '8th', '10th', '12th', 'graduate', 'diploma', 'postgraduate', 'doctorate'];
