import { Combobox } from "@headlessui/react";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { bloodGroupData, RelationData } from "../../shared/data";
import { HUMANITY_BLOCK_API } from "../../shared/Service";
import { API, COMMON } from "../../shared/utility";
import ButtonsLayout from "../ButtonsLayout";
import Spinner from "../Spinner";

const PersonalDetails = ({
  currentTab,
  changeTab,
  setShowSuccessModal,
  isUpdating,
  userId,
}) => {
  const [searchBlockInput, setSearchBlockInput] = useState("");
  const [data, setData] = useState({
    userId: userId
      ? userId
      : localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : null,
    familyId: localStorage.getItem("selfFamilyId")
      ? localStorage.getItem("selfFamilyId")
      : "",
    parentId: localStorage.getItem("parentId")
      ? localStorage.getItem("parentId")
      : null,
    relativeName: localStorage.getItem("selfDetails")
      ? JSON.parse(localStorage.getItem("selfDetails")).name
      : "",
    relation: "",
    name: "",
    gender: "",
    callingPhoneNo: "",
    whatsappPhoneNo: "",
    address: localStorage.getItem("selfDetails")
      ? JSON.parse(localStorage.getItem("selfDetails")).address
      : "",
    dob: null,
    fatherName: "",
    country: localStorage.getItem("selfDetails")
      ? JSON.parse(localStorage.getItem("selfDetails")).country
      : "",
    state: localStorage.getItem("selfDetails")
      ? JSON.parse(localStorage.getItem("selfDetails")).state
      : "",
    district: localStorage.getItem("selfDetails")
      ? JSON.parse(localStorage.getItem("selfDetails")).district
      : "",
    village: localStorage.getItem("selfDetails")
      ? JSON.parse(localStorage.getItem("selfDetails")).village
      : "",
    block: localStorage.getItem("selfDetails")
      ? JSON.parse(localStorage.getItem("selfDetails")).block
      : "",
    bloodGroup: null,
    caste: localStorage.getItem("selfDetails")
      ? JSON.parse(localStorage.getItem("selfDetails")).caste
      : "",
    vehicle: localStorage.getItem("selfDetails")
      ? JSON.parse(localStorage.getItem("selfDetails")).vehicle
      : "",
    politicalParty: "",
    socialDesignation: "",
    knownOfficers: "",
    cCategory: false,
    carer: "",
  });

  const [selectedBlock, setSelectedBlock] = useState(data.block);
  const [countries, setCountries] = useState([]);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);
  const [blocks, setblocks] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(true);
  const [updateData, setUpdateData] = useState({});

  const [isBlockFilled, setIsBlockFilled] = useState(false);

  const handleInputChange = (event) => {
    // console.log(event.target.name, ":", event.target.value);
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleUpdateChange = (event) => {
    setUpdateData({ ...updateData, [event.target.name]: event.target.value });
  };

  const GetPersonalDetail = async () => {
    if (data.userId) {
      API.GET_PERSONAL_DETAIL(data.userId)
        .then((res) => {
          res?.data?.data?.length === 0
            ? setIsNewRecord(true)
            : setIsNewRecord(false);
          localStorage.setItem("userId", data.userId);
          const {
            userId,
            familyId,
            name,
            email,
            dob,
            gender,
            callingPhoneNo,
            whatsappPhoneNo,
            fatherName,
            country,
            district,
            caste,
            bloodGroup,
            vehicle,
            socialDesignation,
            politicalParty,
            knownOfficers,
            state,
            address,
            relation,
            village,
            block,
            skills,
            parentId,
            cCategory,
            carer,
          } = res.data.data;
          console.log(dob);

          setData({
            ...data,
            userId,
            familyId,
            name,
            email,
            dob: dob?.slice(0, 10),
            gender,
            callingPhoneNo,
            whatsappPhoneNo,
            fatherName,
            country,
            district,
            caste,
            bloodGroup,
            vehicle,
            socialDesignation,
            politicalParty,
            knownOfficers,
            state,
            address,
            relation,
            village,
            block,
            skills,
            parentId,
            cCategory,
            carer,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const getCountries = () => {
    COMMON.GET_ALL_COUNTRIES()
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((e) => {
        console.log(e?.response?.data?.message);
      });
  };
  const getstates = (CountryCode) => {
    COMMON.GET_ALL_STATES(CountryCode)
      .then((res) => {
        setstates(res.data.data);
      })
      .catch((e) => {
        console.log(e.response.data.message);
      });
  };

  const getcities = (countryCode, stateCode) => {
    COMMON.GET_ALL_CITIES(countryCode, stateCode)
      .then((res) => {
        setcities(res.data.data);
      })
      .catch((e) => {
        console.log(e.response.data.message);
      });
  };
  const searchBlock = (blockname) => {
    axios
      .post(HUMANITY_BLOCK_API, {
        blockname: blockname,
      })
      .then((res) => {
        setblocks(res.data.block);
      })
      .catch((e) => {
        console.log(e.response.data.message);
      });
  };
  useEffect(() => {
    GetPersonalDetail();
    getCountries();
  }, [0]);

  useEffect(() => {
    const val =
      selectedBlock.blockname === null || selectedBlock.blockname === undefined;
    setIsBlockFilled(!val);
  }, [data]);

  return (
    <div className={`${currentTab === 0 ? "block" : "hidden"}`}>
      {isLoading ? (
        <div className="w-full h-[50vh] flex items-center justify-center  bg-white  rounded-lg">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex flex-col w-full px-3 py-5 bg-white rounded-lg sm:p-5 ">
            <form action="#" className="flex flex-col">
              <div className="flex flex-col md:grid md:grid-cols-2 md:gap-y-6 md:gap-x-2">
                {/* Relative Name */}
                <div className="flex flex-col mb-4 md:p-1 md:mb-0">
                  <label
                    htmlFor="name"
                    className="text-sm text-black capitalize required"
                  >
                    Relative Name
                  </label>
                  <input
                    type="text"
                    name="relativeName"
                    disabled
                    value={data.relativeName}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                    className="px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  />
                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    please enter valid input
                  </p>
                </div>

                {/* Relation*/}
                <div className="flex flex-col mb-4 md:p-1 md:mb-0">
                  <label
                    htmlFor="relation"
                    className="text-sm text-black capitalize required"
                  >
                    Relation
                  </label>
                  <select
                    className="px-3 py-2 text-black bg-transparent border border-gray-300 rounded-md sm:px-4 focus:outline-none focus:ring-4 focus:ring-gray-300"
                    name="relation"
                    value={data.relation}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                  >
                    {!data.relativeName && (
                      <option value="self" hidden>
                        Self
                      </option>
                    )}
                    <option value="none" hidden>
                      ----none----
                    </option>

                    {data.parentId &&
                      RelationData.map(({ label, value }, ind) => {
                        return (
                          <option
                            value={value}
                            key={ind}
                            defaultValue={"father"}
                          >
                            {label}
                          </option>
                        );
                      })}
                  </select>
                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    please enter valid input
                  </p>
                </div>

                {/* Name */}
                <div className="flex flex-col mb-4 md:p-1 md:mb-0">
                  <label
                    htmlFor="name"
                    className="text-sm text-black capitalize required"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    maxLength={20}
                    pattern="[A-Za-z\\s]*"
                    value={data.name}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                    className="px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  />
                  <div className="flex items-center mt-2">
                    <input
                      id="isCategoryC"
                      type="checkbox"
                      title="Category C"
                      checked={data.cCategory}
                      onChange={(e) => {
                        if (!isNewRecord) {
                          setUpdateData({
                            ...updateData,
                            cCategory: e.target.checked,
                          });
                        }
                        setData({ ...data, cCategory: e.target.checked });
                        localStorage.setItem(
                          "isCategoryC",
                          JSON.stringify(e.target.checked)
                        );
                      }}
                    />
                    <label
                      htmlFor="isCategoryC"
                      className="ml-2 text-sm text-black capitalize cursor-pointer"
                    >
                      Category C
                    </label>
                  </div>
                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    please enter valid input
                  </p>
                </div>

                {/* Father Name */}
                <div className="flex flex-col mb-4 md:p-1 md:mb-0">
                  <label
                    htmlFor="fatherName"
                    className="text-sm text-black capitalize required"
                  >
                    Father Name/Husband Name
                  </label>
                  <input
                    type="text"
                    name="fatherName"
                    maxLength={20}
                    value={data.fatherName}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                    className="px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  />
                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    please enter valid input
                  </p>
                </div>

                {/* C/O */}
                {data.cCategory && (
                  <div className="flex flex-col mb-4 md:p-1 md:mb-0">
                    <label
                      htmlFor="carer"
                      className="text-sm text-black capitalize required"
                    >
                      C/O
                    </label>
                    <input
                      type="text"
                      name="carer"
                      maxLength={20}
                      value={data.carer}
                      onChange={(e) => {
                        if (!isNewRecord) {
                          handleUpdateChange(e);
                        }
                        handleInputChange(e);
                      }}
                      className="px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                    />
                    <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                      please enter valid input
                    </p>
                  </div>
                )}

                {/* Age */}
                <div className="flex flex-col mb-4 md:p-1 md:mb-0">
                  <label
                    htmlFor="dob"
                    className={`text-sm text-black capitalize ${
                      !data.cCategory && "required"
                    }`}
                  >
                    Date of birth
                  </label>

                  <input
                    type="date"
                    name="dob"
                    value={data.dob}
                    placeholder="dd-mm-yyyy"
                    min={
                      new Date(new Date("1847-01-01"))
                        .toISOString()
                        .split("T")[0]
                    }
                    max={new Date(new Date()).toISOString().split("T")[0]}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                    className="!uppercase w-full px-3 sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300 bg-transparent"
                  />
                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    please enter valid input
                  </p>
                </div>

                {/* Gender */}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <label
                    htmlFor="gender"
                    className="text-sm text-black capitalize required"
                  >
                    Gender
                  </label>
                  <select
                    className="px-3 py-2 text-black bg-transparent border border-gray-300 rounded-md sm:px-4 focus:outline-none focus:ring-4 focus:ring-gray-300"
                    name="gender"
                    value={data.gender}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                  >
                    <option hidden> Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    please enter valid input
                  </p>
                </div>

                {/* Phone Number Calling */}
                <div className="flex flex-col mb-4 md:p-1 md:mb-0">
                  <label
                    htmlFor="phone-call"
                    className="text-sm text-black capitalize required"
                  >
                    Phone Number ( Calling )
                  </label>

                  <input
                    type="number"
                    name="callingPhoneNo"
                    value={data.callingPhoneNo}
                    onChange={(e) => {
                      var number = e.target.value;
                      var compare = /^[0-9]{1,10}$/g;
                      if (number.match(compare) || number.length <= 1) {
                        if (!isNewRecord) {
                          setUpdateData({
                            ...updateData,
                            callingPhoneNo: number,
                          });
                        }
                        setData({ ...data, callingPhoneNo: number });
                      }
                    }}
                    required
                    className="w-full px-3 py-2 transition duration-300 border border-gray-300 rounded rounded-md sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300 "
                  />

                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    invalid phone number
                  </p>
                </div>
                {/* Phone Number Whatsapp */}
                <div className="flex flex-col mb-4 md:p-1 md:mb-0">
                  <label
                    htmlFor="phone-whatsapp"
                    className="text-sm text-black capitalize "
                  >
                    Phone Number ( Whatsapp )
                  </label>
                  <input
                    type="number"
                    name="whatsappPhoneNo"
                    min={"0000000000"}
                    max={"9999999999"}
                    value={data.whatsappPhoneNo}
                    onChange={(e) => {
                      var number = e.target.value;
                      var compare = /^[0-9]{1,10}$/g;

                      if (number.match(compare) || number.length <= 1) {
                        if (!isNewRecord) {
                          setUpdateData({
                            ...updateData,
                            whatsappPhoneNo: number,
                          });
                        }
                        setData({ ...data, whatsappPhoneNo: number });
                      }
                    }}
                    className="w-full px-3 py-2 transition duration-300 border border-gray-300 rounded rounded-md sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300 "
                  />

                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    invalid phone number
                  </p>
                </div>

                {/* Address  */}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <div className="flex items-center justify-between">
                    <label htmlFor="address" className="text-sm text-black ">
                      Address
                    </label>
                  </div>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={data.address}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                    className="w-full px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  />
                  <p className="hidden mt-2 text-sm peer-invalid:block text-red">
                    please enter valid input
                  </p>
                </div>

                {/*Village*/}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <div className="flex items-center justify-between">
                    <label htmlFor="address" className="text-sm text-black">
                      Village
                    </label>
                  </div>
                  <input
                    type="text"
                    name="village"
                    maxLength={30}
                    pattern="[A-Za-z\\s]*"
                    value={data.village}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                    required
                    className="w-full px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  />
                  <p className="hidden mt-2 text-sm peer-invalid:block text-red">
                    please enter valid input
                  </p>
                </div>

                {/* block */}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="district"
                      className="text-sm text-black capitalize "
                    >
                      Block
                    </label>
                  </div>

                  <Combobox
                    value={
                      isNewRecord
                        ? data.block
                          ? data.block
                          : selectedBlock?.blockname
                        : data.block
                    }
                    onChange={(blockDetail) => {
                      // console.log(blockDetail);
                      // console.log(blockDetail.blockname !== undefined);
                      // console.log(blockDetail !== null || blockDetail !== undefined);

                      if (
                        blockDetail.blockname !== null &&
                        blockDetail.blockname !== undefined
                      ) {
                        if (!isNewRecord) {
                          setUpdateData({
                            ...updateData,
                            block: blockDetail.blockname,
                            state: blockDetail.statename,
                            district: blockDetail.districtname,
                            country: "India",
                          });
                        }
                        setData({
                          ...data,
                          block: blockDetail.blockname,
                          state: blockDetail.statename,
                          district: blockDetail.districtname,
                          country: "India",
                        });
                      } else if (
                        blockDetail !== null ||
                        blockDetail !== undefined
                      ) {
                        if (!isNewRecord) {
                          setUpdateData({ ...updateData, block: blockDetail });
                        }
                        setData({ ...data, block: blockDetail });
                      }

                      setSelectedBlock(blockDetail);
                    }}
                  >
                    <div className="relative mt-1">
                      <Combobox.Input
                        type="search"
                        className="w-full px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                        displayValue={() =>
                          isNewRecord
                            ? data.block
                              ? data.block
                              : selectedBlock?.blockname
                              ? selectedBlock?.blockname
                              : selectedBlock
                            : data.block
                        }
                        onChange={(event) => {
                          setSearchBlockInput(event.target.value);
                          if (event.target.value.trim() === "") {
                            if (!isNewRecord) {
                              setUpdateData({
                                ...updateData,
                                block: "",
                                state: "",
                                district: "",
                                country: "",
                              });
                            }
                            setData({
                              ...data,
                              block: "",
                              state: "",
                              district: "",
                              country: "",
                            });
                            setSelectedBlock("");
                          }

                          searchBlock(event.target.value);
                        }}
                      />

                      <Combobox.Options className="absolute z-[99999999999999] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {blocks.length === 0 ? (
                          <Combobox.Option
                            className={`relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900`}
                            value={searchBlockInput}
                          >
                            {({ selected, active }) => (
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {searchBlockInput}
                              </span>
                            )}
                          </Combobox.Option>
                        ) : (
                          blocks.length > 0 &&
                          blocks.map((Block) => (
                            <Combobox.Option
                              key={Block.blockid}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active ? "bg-blue-300" : "text-gray-900"
                                }`
                              }
                              value={Block}
                            >
                              {({ selected, active }) => (
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {Block.blockname} / {Block.districtname}
                                </span>
                              )}
                            </Combobox.Option>
                          ))
                        )}
                      </Combobox.Options>
                    </div>
                  </Combobox>

                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    please enter valid input
                  </p>
                </div>

                {/* Country */}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="country"
                      className="text-sm text-black capitalize required"
                    >
                      Country
                    </label>
                  </div>
                  <select
                    className="w-full px-3 py-2 text-black bg-transparent border border-gray-300 rounded-md sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                    name="country"
                    value={data.country}
                    onChange={(e) => {
                      const country = JSON.parse(e.target.value);
                      !isBlockFilled && setCountryCode(country.isoCode);
                      !isBlockFilled && getstates(country.isoCode);

                      if (!isNewRecord) {
                        !isBlockFilled &&
                          setUpdateData({
                            ...updateData,
                            country: country.name,
                          });
                      }
                      !isBlockFilled &&
                        setData({
                          ...data,
                          country: country.name,
                        });
                    }}
                    disabled={isBlockFilled}
                  >
                    <option className="text-sm text-black capitalize" hidden>
                      {isBlockFilled
                        ? data.country
                        : isNewRecord
                        ? data.country !== ""
                          ? data.country
                          : "Select Country"
                        : data.country.trim() !== ""
                        ? data.country
                        : "Select Country"}
                    </option>
                    {countries?.map((country) => {
                      return (
                        <option
                          key={country.code}
                          value={JSON.stringify(country)}
                          className="text-sm capitalize cursor-pointer"
                        >
                          {country.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* State */}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="state"
                      className="text-sm text-black capitalize required"
                    >
                      State
                    </label>
                  </div>
                  <select
                    className="w-full px-3 py-2 text-black bg-transparent border border-gray-300 rounded-md sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                    name="state"
                    value={data.state}
                    onChange={(e) => {
                      const state = JSON.parse(e.target.value);

                      getcities(countryCode, state.isoCode);

                      if (!isNewRecord) {
                        setUpdateData({ ...updateData, state: state.name });
                      }
                      setData({
                        ...data,
                        state: state.name,
                      });
                    }}
                    disabled={isBlockFilled}
                  >
                    <option className="text-sm text-black capitalize" hidden>
                      {isBlockFilled
                        ? data.state
                        : isNewRecord
                        ? data.state.trim() !== ""
                          ? data.state
                          : "Select State"
                        : data.state.trim() !== ""
                        ? data.state
                        : "Select State"}
                    </option>

                    {states.length !== 0 &&
                      states.map((state) => {
                        return (
                          <option
                            key={state.isoCode}
                            value={JSON.stringify(state)}
                            className="text-sm capitalize cursor-pointer"
                            selected={data.state === state.name}
                          >
                            {state.name}
                          </option>
                        );
                      })}
                  </select>
                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    please enter valid input
                  </p>
                </div>
                {/* district */}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="district"
                      className="text-sm text-black capitalize required"
                    >
                      District
                    </label>
                  </div>
                  <select
                    name="district"
                    value={data.district}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                    disabled={isBlockFilled}
                    required
                    className="w-full px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  >
                    <option className="text-sm text-black capitalize" hidden>
                      {isBlockFilled
                        ? data.district
                        : isNewRecord
                        ? data.district.trim()
                          ? data.district
                          : "Select District"
                        : data.district.trim()
                        ? data.district
                        : "Select District"}
                    </option>
                    {cities.length !== 0 &&
                      cities.map((district) => {
                        return (
                          <option
                            key={district.name}
                            value={district.name}
                            selected={data.district === district.name}
                            className="text-sm capitalize"
                          >
                            {district.name}
                          </option>
                        );
                      })}
                  </select>
                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    please enter valid input
                  </p>
                </div>

                {/* Cast  */}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="address"
                      className="text-sm text-black required"
                    >
                      Caste
                    </label>
                  </div>
                  <input
                    type="text"
                    name="caste"
                    maxLength={20}
                    pattern="[A-Za-z\\s]*"
                    value={data.caste}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                    required
                    className="w-full px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  />
                  <p className="hidden mt-2 text-sm peer-invalid:block text-red">
                    please enter valid input
                  </p>
                </div>
                {/* blood Group */}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <label
                    htmlFor="bloodGroup"
                    className="text-sm text-black capitalize"
                  >
                    Blood Group
                  </label>
                  <select
                    className="px-3 py-2 text-black bg-transparent border border-gray-300 rounded-md sm:px-4 focus:outline-none focus:ring-4 focus:ring-gray-300"
                    name="bloodGroup"
                    value={data.bloodGroup}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                  >
                    <option className="text-gray-300" value={null}>
                      Select Blood Group
                    </option>
                    {bloodGroupData.map(({ label, value }, ind) => {
                      return (
                        <option
                          value={value}
                          key={ind}
                          selected={data?.bloodGroup?.toLowerCase() === value}
                        >
                          {label}
                        </option>
                      );
                    })}
                  </select>
                  <p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">
                    please enter valid input
                  </p>
                </div>

                {/* Vehicle  */}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <div className="flex items-center justify-between">
                    <label htmlFor="address" className="text-sm text-black ">
                      Vehicle
                    </label>
                  </div>
                  <input
                    type="text"
                    name="vehicle"
                    maxLength={50}
                    value={data.vehicle}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                    className="w-full px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  />
                  <p className="hidden mt-2 text-sm peer-invalid:block text-red">
                    please enter valid input
                  </p>
                </div>

                {/* Social Designation  */}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <div className="flex items-center justify-between">
                    <label htmlFor="address" className="text-sm text-black ">
                      Social Designation
                    </label>
                  </div>
                  <input
                    type="text"
                    name="socialDesignation"
                    maxLength={100}
                    pattern="[A-Za-z\\s]*"
                    value={data.socialDesignation}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                    className="w-full px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  />
                  <p className="hidden mt-2 text-sm peer-invalid:block text-red">
                    please enter valid input
                  </p>
                </div>
                {/*politicalParty*/}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <div className="flex items-center justify-between">
                    <label htmlFor="address" className="text-sm text-black ">
                      Political Party
                    </label>
                  </div>
                  <input
                    type="text"
                    name="politicalParty"
                    maxLength={20}
                    pattern="[A-Za-z\\s]*"
                    value={data.politicalParty}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                    className="w-full px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  />
                  <p className="hidden mt-2 text-sm peer-invalid:block text-red">
                    please enter valid input
                  </p>
                </div>
                {/*knownOfficers*/}
                <div className="flex flex-col w-full mb-4 md:p-1 md:mb-0">
                  <div className="flex items-center justify-between">
                    <label htmlFor="address" className="text-sm text-black ">
                      Known Officers
                    </label>
                  </div>
                  <input
                    type="text"
                    name="knownOfficers"
                    maxLength={20}
                    pattern="[A-Za-z\\s]*"
                    value={data.knownOfficers}
                    onChange={(e) => {
                      if (!isNewRecord) {
                        handleUpdateChange(e);
                      }
                      handleInputChange(e);
                    }}
                    className="w-full px-3 py-2 transition duration-300 border border-gray-300 rounded sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                  />
                  <p className="hidden mt-2 text-sm peer-invalid:block text-red">
                    please enter valid input
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div className="py-1 my-5 bg-white rounded-lg">
            <ButtonsLayout
              currentTab={currentTab}
              changeTab={changeTab}
              data={data}
              showSaveButton={isNewRecord}
              updateData={updateData}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PersonalDetails;
