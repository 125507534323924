import React from 'react';
import {instructionArr} from '../shared/data';
const Home = () => {
	return (
		<div className="min-h-screen py-6 bg-blue-100">
			<div className="p-4 m-auto w-[90%] md:max-w-6xl bg-white shadow-md shadow-gray-300 rounded-md mt-10 min-h-[80vh]">
				<div className="flex items-center justify-center">
					<h2 className="my-2 mb-2 text-2xl font-semibold leading-8 text-gray-900 capitalize">Welcome to DSS data collection portal</h2>
				</div>
				<div className="text-lg font-semibold leading-8 text-center text-gray-900 text-red-500">
					अधूरी जानकारी होने पर आपने फ़ॉर्म नहीं भरना जी, सतर्कता व सही जानकारी से फ़ॉर्म भरना ही आपकी सेवा है जी।
				</div>
				<div className="max-w-lg mt-5 sm:mx-auto md:max-w-none">
					{instructionArr.map((instruction, ind) => (
						<div key={ind} className="relative flex flex-col gap-6 mb-4 sm:flex-row md:flex-col lg:flex-row">
							<div className="w-full">
								<p className="text-lg font-semibold leading-8 text-gray-900">{instruction.name}</p>
								<p className="px-4 mt-2 text-base leading-7 text-gray-600">{instruction.description}</p>

								<ol className="list-decimal px-6">
									{instruction.subDescription.length > 0 &&
										instruction.subDescription.map((value, index) => (
											<li key={index} className="mt-2 text-base leading-7 text-gray-600">
												{value}
											</li>
										))}
								</ol>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Home;
