import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Pagination from '../../components/admin/Pagination';
import Spinner from '../../components/Spinner';
import {BASEURL, errorToast} from '../../shared/Service';
import {API} from '../../shared/utility';

const AllUsers = () => {
	const navigate = useNavigate();
	const [phoneNo, setPhoneNo] = useState('');
	const [selectedState, setSelectedState] = useState('');
	const [selectedCity, setSelectedCity] = useState('');
	const [allUsers, setAllUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [oldResults, setOldResults] = useState([]);
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(10);
	const [states, setstates] = useState([]);
	const [cities, setcities] = useState([]);
	const [totalDocuments, setTotalDocuments] = useState('');

	const queryString = ({phoneNo, selectedState, selectedCity, size, page}) => {
		var query = '?';
		if (phoneNo) query = `${query}phoneNo=${phoneNo}&`;
		if (selectedState) query = `${query}state=${selectedState}&`;
		if (selectedCity) query = `${query}district=${selectedCity}&`;
		query = `${query}size=${size}&`;
		query = `${query}page=${page}&`;

		return query.substring(0, query.length - 1);
	};

	const handleLoadUsers = async () => {
		setIsLoading(true);

		const query = queryString({
			phoneNo: phoneNo.trim(),
			selectedState: selectedState.trim(),
			selectedCity: selectedCity.trim(),
			size,
			page,
		});

		API.GET_ALL_TOTAL_USER(query)
			.then((response) => {
				console.log(response.data.data.users);
				setAllUsers(response.data.data.users);
				setTotalDocuments(response.data.data.totalDocuments);

				if (!phoneNo) setOldResults(response.data.data);
			})
			.catch((err) => {
				console.log(err);
				errorToast(err.response.data.message);
			})
			.finally(() => setIsLoading(false));
	};

	const getstates = (countryCode) => {
		axios
			.get(`${BASEURL}/states/${countryCode}`)
			.then((res) => {
				setstates(res?.data?.data);
			})
			.catch((e) => {
				console.log(e.response.data.message);
			});
	};
	const getcities = (countryCode, stateCode) => {
		axios
			.get(`${BASEURL}/cities/${countryCode}/${stateCode}`)
			.then((res) => {
				setcities(res.data.data);
			})
			.catch((e) => {
				console.log(e.response.data.message);
			});
	};

	useEffect(() => {
		handleLoadUsers();
	}, [page]);

	useEffect(() => {
		getstates('IN');
		const userDetails = JSON.parse(localStorage.getItem('userInfo'));
		if (userDetails?.role !== 'superadmin') {
			navigate('/');
		}
	}, []);

	return (
		<div className="min-h-screen py-6 bg-blue-100">
			<div className="py-4 m-auto w-[90%] md:max-w-6xl px-4  bg-white shadow-md shadow-gray-300 rounded-md mt-10">
				<p className="text-2xl text-gray-700 md:text-3xl ">List of all Family Head</p>
				<div className="flex flex-wrap items-end">
					{/* phone number */}
					<div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 space-y-1 my-2">
						<label htmlFor="phoneNo" className="text-sm text-gray-400">
							Phone Number
						</label>
						<input
							type="number"
							name="phoneNo"
							value={phoneNo}
							placeholder="enter phone number"
							onChange={(e) => setPhoneNo(e.target.value)}
							className="px-4 py-2 text-gray-400 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200"
						/>
					</div>
					{/* state */}
					<div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 space-y-1 my-2">
						<div className="flex items-center justify-between">
							<label htmlFor="state" className="text-sm text-gray-400 capitalize ">
								State
							</label>
						</div>
						<select
							className="w-full px-3 py-2 text-gray-400 bg-transparent border border-gray-300 rounded-md sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
							name="state"
							onChange={(e) => {
								const state = JSON.parse(e.target.value);

								getcities('IN', state.isoCode);
								setSelectedState(state.name);
							}}>
							<option className="text-sm text-gray-400 capitalize" hidden>
								Select State
							</option>

							{states.length !== 0 &&
								states.map((state) => {
									return (
										<option key={state.isoCode} value={JSON.stringify(state)} className="text-sm capitalize cursor-pointer">
											{state.name}
										</option>
									);
								})}
						</select>
					</div>
					{/* district */}
					<div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 space-y-1 my-2">
						<div className="flex items-center justify-between">
							<label htmlFor="district" className="text-sm text-gray-400 capitalize ">
								District
							</label>
						</div>
						<select
							name="district"
							onChange={(e) => {
								setSelectedCity(e.target.value);
							}}
							required
							className="w-full px-3 text-gray-400 py-2 transition duration-300 border border-gray-300 rounded-md  sm:px-4 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300">
							<option className="text-sm text-gray-400 capitalize" hidden>
								Select District
							</option>
							{cities.length !== 0 &&
								cities.map((district) => {
									return (
										<option key={district.name} value={district.name} className="text-sm text-gray-400 capitalize">
											{district.name}
										</option>
									);
								})}
						</select>
						<p className="hidden mt-2 text-sm text-red-500 capitalize peer-invalid:block">please enter valid input</p>
					</div>

					<div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 my-2">
						<button className="p-2 px-4 text-white bg-blue-500 rounded-md shadow-sm shadow-gray-700" onClick={handleLoadUsers}>
							Search
						</button>
					</div>
				</div>
				<div className="overflow-scroll">
					<table className="min-w-full my-4 bg-blue-100 divide-y divide-gray-200 shadow-md table-fixed shadow-gray-300">
						<thead className="">
							<tr>
								{heading &&
									heading.map((tr) => {
										return (
											<th key={tr} scope="col" className="p-3 text-xs font-bold text-left text-gray-500 uppercase md:px-6 md:py-3 whitespace-nowrap">
												{tr}
											</th>
										);
									})}
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">
							{isLoading ? (
								<td colSpan={8}>
									<Spinner css={'my-10'} />
								</td>
							) : (
								allUsers.length > 0 &&
								allUsers.map((user, index) => {
									return (
										user && (
											<tr key={index} className=" hover:bg-blue-200 ">
												<td className={tdClass}>{size * (page - 1) + index + 1}</td>
												<td className={`${tdClass} cursor-pointer  hover:text-blue-600 hover:underline`} onClick={() => navigate(`/family/${user.userId}`)}>
													{user?.name || 'NA'}
												</td>
												<td className={tdClass}>{user?.fatherName || 'NA'}</td>
												<td className={tdClass}>{user?.state || 'NA'}</td>
												<td className={tdClass}>{user?.district || 'NA'}</td>
												<td className={tdClass}>{user?.callingPhoneNo || 'NA'}</td>
												<td className={tdClass}>{user?.Admin?.name || 'NA'}</td>
												<td className={tdClass}>
													<button className="p-2 px-4 text-sm text-white bg-blue-500 rounded-md shadow-sm shadow-gray-700" onClick={() => navigate(`/users/${user.userId}`)}>
														View User Info
													</button>
												</td>
											</tr>
										)
									);
								})
							)}
						</tbody>
					</table>
				</div>
				{!isLoading ? <Pagination totalDocuments={totalDocuments} size={size} setPage={setPage} page={page} /> : null}
			</div>
		</div>
	);
};

const tdClass = 'md:px-6 md:py-4 p-3 text-left text-sm text-gray-800 whitespace-nowrap capitalize';
const heading = [
	'S.No',
	'Name',
	'Father Name',
	'State',
	'District',
	'Phone No.',
	'Added By',
	'Action',

	// , 'Action'
];
export default AllUsers;
