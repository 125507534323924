import React from 'react';
import {Toaster} from 'react-hot-toast';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from '../components/Navbar';
import AllUsers from '../screens/admin/AllUsers';
import RelativeUsers from '../screens/admin/RelativeUsers';
import UserProfile from '../screens/admin/UserProfile';
import Details from '../screens/Details';
import Home from '../screens/Home';
import Login from '../screens/Login';
import NotFound from '../screens/NotFound';
// import Register from '../screens/Register';
import UpdatePassword from '../screens/UpdatePassword';
import AllAdmin from '../screens/superAdmin/AllAdmin';
import TotalUsers from '../screens/superAdmin/AllUsers';
import Stats from '../screens/superAdmin/Stats';
import PrivateRoutes from './PrivateRoutes';

const MyRoutes = () => {
	return (
		<div>
			<Router>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/update-password" element={<PrivateRoutes Component={UpdatePassword} />} />
					{/* <Route path="/register" element={<Register />} /> */}

					<Route path="/*" element={<InsideRoutes />} />
				</Routes>
			</Router>
			<Toaster
				position="top-right"
				reverseOrder={false}
				gutter={8}
				toastOptions={{
					duration: 5000,
					style: {
						background: '#363636',
						color: '#fff',
					},
					success: {
						duration: 5000,
						theme: {
							primary: 'blue',
							secondary: 'black',
						},
					},
				}}
			/>
		</div>
	);
};

const InsideRoutes = () => {
	const userDetails = JSON.parse(localStorage.getItem('userInfo'));

	return (
		<>
			<Navbar />
			<Routes>
				<Route path="/" element={<PrivateRoutes Component={userDetails?.role === 'superadmin' ? Stats : Home} />} />
				<Route path="/add-entry" element={<PrivateRoutes Component={Details} />} />
				<Route path="/update-entry/:id" element={<PrivateRoutes Component={Details} />} />
				<Route path="/users" element={<PrivateRoutes Component={AllUsers} />} />
				<Route path="/users/:id" element={<PrivateRoutes Component={UserProfile} />} />
				<Route path="/family/:id" element={<PrivateRoutes Component={RelativeUsers} />} />

				{/*  super-admin routes */}
				<Route path="/admin/all-admin" element={<PrivateRoutes Component={AllAdmin} />} />
				<Route path="/admin/all-users" element={<PrivateRoutes Component={TotalUsers} />} />

				{/* catch for not found */}
				<Route path="/*" element={<NotFound />} />
			</Routes>
		</>
	);
};

export default MyRoutes;
